import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Activevehiclecard from "./Vehiclecard.component.jsx"
import ActivePassportCard from "./passportcard.component.jsx";
import ActiveKeyCard from "./keycard.component.jsx";
import ActiveBagCard from "./backpackcard.component.jsx";
import ActiveTrackercard from "./trackercard.component.jsx";
import ActiveCard from "./activecard.component.jsx";
import ActiveEarphoneCard from "./earphonecard.component.jsx";
import ActiveGenericcard from "./generic.component.jsx";
import ActiveWalletcard from "./walletcard.component.jsx";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Consentform from "../concentform/Consentform.jsx";
import Sidebar from "../sidebar/sidebar.component.jsx";
import {Snackbar} from "@mui/material"
import Alert from "@mui/material/Alert";
import {
  BsLayoutSidebarInset,
  BsLayoutSidebarInsetReverse,
} from "react-icons/bs";
// import Modal from "@material-ui/core/Modal";
import "./dashboard.component.css";
// import qrcode from "../../assets/qr-code.png";
import { QrReader } from "react-qr-reader";
import Navbar from "../header/header.component.jsx";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
// import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import OldTagsCard from "./oldtags.component.jsx";
import ActivePassportcard from "./passportcard.component.jsx";
import Humancard from "./humancard.component.jsx";
import Dolphincard from "./Dolphincard.component.jsx";
import { switchUrls } from "../../api/index.js";
import Userdetailscomponent from "./Userdetailscomponent.jsx";
// import updateLocationForTags from "./updatelocation.jsx";
import { PRODUCTCATEGORY_ETAGS } from "../../utils/setValues.js";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
const baseURL = switchUrls("dolphin");
const gatewayUrl = switchUrls("gateway");
const bag_frontend = switchUrls("bag_frontend");
const passport_frontend = switchUrls("passport_frontend");
const vehicle_frontned = switchUrls("vehicle_frontend");

// const LocationErrorModal = ({ open, handleClose, handleRetry }) => {
//   return (
//     <Modal open={open} onClose={handleClose}>
//       <div
//         style={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           padding: "20px",
//           background: "white",
//           borderRadius: "8px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           maxWidth: "300px", // Adjust the width as needed
//           textAlign: "center",
//         }}
//       >
//         <p style={{ marginBottom: "15px" }}>
//           Unable to retrieve your location. Please turn on your GPS.
//         </p>
//         <button
//           onClick={handleRetry}
//           style={{
//             padding: "10px 15px",
//             background: "#007BFF",
//             color: "#fff",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//         >
//           Retry
//         </button>
//       </div>
//     </Modal>
//   );
// };

function Dashboard() {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }
  const location1 = useLocation();
  const params = new URLSearchParams(location1?.search);
  const token = readCookie("token");
  const uid = localStorage.getItem("uid");
  const isAdmin = localStorage.getItem("isAdmin");
  const [isAuth, setIsAuth] = useState(token !== null ? true : false);
  const [tagNumber, setTagNumber] = useState("");
  const [activatetag, setActivateTag] = useState("");
  const [showtag, setShowTag] = useState(false);
  const [show, setShow] = useState(false);
  const [tagNotFound, setTagNotFound] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const [deactiveResponse, setDeactiveResponse] = useState([]);
  const [tagall, setTagall] = useState([]);
  const [allResponse, setAllResponse] = useState([]);
  const [lostResponse, setLostResponse] = useState([]);
  const [foundResponse, setFoundResponse] = useState([]);
  const [KeydeactiveResponse, setKeyDeactiveResponse] = useState([]);
  const [KeyallResponse, setKeyAllResponse] = useState([]);
  const [KeylostResponse, setKeyLostResponse] = useState([]);
  const [KeyfoundResponse, setKeyFoundResponse] = useState([]);
  const [BagdeactiveResponse, setBagDeactiveResponse] = useState([]);
  const [BagallResponse, setBagAllResponse] = useState([]);
  const [BaglostResponse, setBagLostResponse] = useState([]);
  const [BagfoundResponse, setBagFoundResponse] = useState([]);
  const [WalletdeactiveResponse, setWalletDeactiveResponse] = useState([]);
  const [WalletallResponse, setWalletAllResponse] = useState([]);
  const [WalletlostResponse, setWalletLostResponse] = useState([]);
  const [WalletfoundResponse, setWalletFoundResponse] = useState([]);
  const [PassportdeactiveResponse, setPassportDeactiveResponse] = useState([]);
  const [PassportallResponse, setPassportAllResponse] = useState([]);
  const [PassportlostResponse, setPassportLostResponse] = useState([]);
  const [PassportfoundResponse, setPassportFoundResponse] = useState([]);
  const [BeaconallResponse, setBeaconAllResponse] = useState([]);
  const [BeaconactiveResponse, setBeaconActiveResponse] = useState([]);
  const [BeacondeactiveResponse, setBeaconDeactiveResponse] = useState([]);
  const [earphonedeactiveResponse, setEarphoneDeactiveResponse] = useState([]);
  const [earphoneallResponse, setEarphoneAllResponse] = useState([]);
  const [earphonelostResponse, setEarphoneLostResponse] = useState([]);
  const [earphonefoundResponse, setEarphoneFoundResponse] = useState([]);
  const [GenericResponse, setGenericResponse] = useState([]);
  const [GenericdeactiveResponse, setGenericDeactiveResponse] = useState([]);
  const [GenericlostResponse, setGenericLostResponse] = useState([]);
  const [GenericfoundResponse, setGenericFoundResponse] = useState([]);
  const [allActivationDates, setAllActivationDates] = useState([]);
  const [passportActivationDates, setPassportActivationDates] = useState([]);
  const [genieActivationDates, setGennieActivationDates] = useState([]);
  const [keyActivationDates, setKeyActivationDates] = useState([]);
  const [bagActivationDates, setBagActivationDates] = useState([]);
  const [walletActivationDates, setWalletActivationDates] = useState([]);
  const [genericActivationDates, setGenericActivationDates] = useState([]);
  const [earphoneActivationDates, setEarphoneActivationDates] = useState([]);
  const [allDeactivationDates, setAllDeactivationDates] = useState([]);
  const [allactivationDates, setAllDates] = useState([]);
  const [humanTagNumbersWithoutLocation, setHumanTagNumbersWithoutLocation] =
    useState([]);
  const [
    passportTagNumbersWithoutLocation,
    setPassportTagNumbersWithoutLocation,
  ] = useState([]);
  const [genieTagNumbersWithoutLocation, setGenieTagNumbersWithoutLocation] =
    useState([]);
  const [keyTagNumbersWithoutLocation, setKeyTagNumbersWithoutLocation] =
    useState([]);
  const [bagTagNumbersWithoutLocation, setBagTagNumbersWithoutLocation] =
    useState([]);
  const [walletTagNumbersWithoutLocation, setWalletTagNumbersWithoutLocation] =
    useState([]);
  const [
    earphoneTagNumbersWithoutLocation,
    setEarphoneTagNumbersWithoutLocation,
  ] = useState([]);
  const [
    genericTagNumbersWithoutLocation,
    setGenericTagNumbersWithoutLocation,
  ] = useState([]);
  const [processedTags, setProcessedTags] = useState([]);
  const [locationAccessDenied, setLocationAccessDenied] = useState(false);
  const [lostDates, setLostDates] = useState([]);
  const [foundDates, setFoundDates] = useState([]);
  const [alignment, setAlignment] = useState( params.get('tab') === "inactive" ? "right" : "left");
  const [allTags, setAllTags] = useState(false);
  const [activeInactive, setActiveInactive] = useState(true);
  const productCategoryCalledRef = useRef(false);
  const [generic, setGeneric] = useState(false);
  const [bag, setBag] = useState(false);
  const vehicleApiCalledRef = useRef(false)
  const bagApiCalledRef = useRef(false);
  const genericApiCalledRef = useRef(false);
  const genieApiCalledRef = useRef(false);
  const keyApiCalledRef = useRef(false);
  const walletApiCalledRef = useRef(false);
  const passportApiCalledRef = useRef(false);
  const earphoneApiCalledRef = useRef(false);
  const humanApiCalledRef = useRef(false);
  const midApiCalledRef = useRef(false);
  const beaconApiCalledRef = useRef(false);
  const [vehicle,setvehicle] = useState(false)
  const [pet, setPet] = useState(false);
  const [key, setKey] = useState(false);
  const [beacon, setBeacon] = useState(false);
  const [wallet, setWallet] = useState(false);
  const [earphone, setEarphone] = useState(false);
  const [passport, setpassport] = useState(false);
  const [lostFound, setLostFound] = useState(false);
  const [apiCallMade, setApiCallMade] = useState(false);
  const [location, setLocation] = useState(null);
  const [concentform, setconcentform] = useState(false);
  const [showLocationErrorModal, setShowLocationErrorModal] = useState(false);
  const [showQR, setshowQR] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  // const [sidebar, setSidebar] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const [vehicleallresponse,setvehicleresponse] = useState([])
  const [vehicledeactiveresponse,setvehicledeactive] = useState([])
  const [vehiclelostresponse,setvehiclelost] = useState([])
  const [vehiclefoundresponse,setvehiclefound] = useState([])
  const [vehicleactiveresponse,setvehicleactive] = useState([])

  const [humanactiveresponse, sethumanactive] = useState([]);
  const [humandeactiveresponse, sethumandeactive] = useState([]);
  const [human, sethuman] = useState(false);
  const [humanallresponse, sethumanresponse] = useState([]);
  const [humanfoundresponse, sethumanfoundreponse] = useState([]);
  const [humanlostresponse, sethumanlostresponse] = useState([]);
  const [humanactivationdates, sethumanactivationdates] = useState([]);
  const [dolphinecard, setdolphincard] = useState(false);
  const [memberslist, setmemberslist] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNotFound = () => setTagNotFound(false);
  const handleActivated = () => setAlreadyActivated(false);

  const [userdetailsmodal, setuserdetailsmodal] = useState(false);
  const [userdetails, setuserdetails] = useState({});

  //L&FState Variables
  const [OldtagResponse, setOldtagResponse] = useState([]);
  const [oldTagsdeactiveResponse, setOldTagsDeactiveResponse] = useState([]);
  const [oldTagsfoundResponse, setOldTagsFoundResponse] = useState([]);
  const [oldTagslostResponse, setoldTagslostResponse] = useState([]);

const isMobile = useMediaQuery("(max-width:600px)");
  const locationAccessDeniedRef = useRef(false);

  //  const [oldTagsfoundResponse, setOldTagsFoundResponse] = useState("");

  const mid = localStorage.getItem("mid");

  localStorage.removeItem("tag_number");
  localStorage.removeItem("Found");
  localStorage.removeItem("Scan");
  const checkcookie = localStorage.getItem("loginemail");

  const checkforConsent = async () => {
    try {
      if (checkcookie?.length) {
        const { data } = await axios.get("/api/getUserprofile", {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });
        // console.log(data?.consentform)
        if (!userdetailsmodal && 
          (data?.consentrequired === undefined || !data?.consentrequired)) {
          // console.log('reched herek')
          setconcentform(true);
        }
      } else {
        return;
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    checkforConsent();
  }, []);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(alignment === "left" ? "right" : "left");
  };
  const renderQRModal = () => {
    return (
      <Dialog
        open={showQR}
        fullWidth
        maxWidth="xs"
        onClose={() => setshowQR(false)}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={(result, error) => {
            if (result) {
              setActivateTag(
                result?.text
                  .split("/")
                  [result?.text.split("/").length - 1].toUpperCase()
              );
              setshowQR(false);
            }
            if (error) {
              console.error(error.message);
            }
          }}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          sessionStorage.setItem("latitude", latitude);
          sessionStorage.setItem("longitude", longitude);
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error.message);
          setShowLocationErrorModal(true);
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    axios
      .post(`${baseURL}/api/dolphin/getacceptedlist`, { currentUserUid: uid })
      .then(({ data }) => {
        // console.log(data);

        setdolphincard(true);
        setmemberslist(data?.requestData);
      })
      .catch((er) => {
        //console.log(er);
      });

    if (isAuth) {
      axios
        .get("/api/getUserProfile", {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setTagall([res.data.tags]);
        })
        .catch((error) => {
          //console.error(error.message);
        });

      //api call to check whether all userdetails are present
      axios
        .get(`${gatewayUrl}/api/checkUserdetails`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then(({ data }) => {
          if (data?.status && data?.availabledata) {
            setuserdetails(data?.availabledata);
            setuserdetailsmodal(true);
          }
        })
        .catch((er) => {
          console.error(er);
        });
    }
    const storedTagNumber = sessionStorage.getItem("tag_number");
    if (storedTagNumber) {
      setActivateTag(storedTagNumber);
      setShowTag(true);
    }

    // getLocation(); // Call the function when the component mounts

    // Add any cleanup logic if needed
    return () => {
      // Cleanup logic, if necessary
    };
  }, []);

  function handleLostFound() {
    setAllTags(false);
    setActiveInactive(false);
    setLostFound(true);

    // if (sidebar === false) {
    //   setSidebar(!sidebar);
    // }
  }

  function handleAllTags() {
    console.log("alltagsgss");
    setAllTags(true);
    setActiveInactive(false);
    setLostFound(false);

    // if (sidebar === false) {
    //   setSidebar(!sidebar);
    // }
  }

  function handleActiveInactive() {
    setAllTags(false);
    setActiveInactive(true);
    setLostFound(false);

    // if (sidebar === false) {
    //   setSidebar(!sidebar);
    // }
  }

  const handleTagNumberChange = (e) => {
    // console.log(e.target.value, "Valuesss");
    const value = e.target.value.toUpperCase();
    sessionStorage.getItem("tag_number", value);
    //setTagNumber(value);
    // setActivateTag(value);
  };

  const handleCloseTag = () => {
    setShow(false);
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value.toUpperCase();
    const filteredValue = inputValue.replace(/\s+/g, "");
    const limitedValue = filteredValue.slice(0, 8);
    setActivateTag(limitedValue);
  };

  // function getLFtags(data) {
  //   setOldtagResponse(data);
  // }

  // function getOldFoundTags(data) {
  //   setOldTagsFoundResponse(data);
  // }

  // function getOldDeactivatetags(data) {
  //   setOldTagsDeactiveResponse(data);
  // }

  // function getoldTagslost(data) {
  //   setoldTagslostResponse(data);
  // }
  //SQL_Close
  // const updatePsprtDetails = (userId) => {
  //   axios
  //     .get(`/api/getPassportDetailsByUid/${userId}`, {
  //       headers: {
  //         Authorization: token,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       // console.log("PASSPORT DETAILS Updated --->", res);
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //     });
  // };

  // const tagNumbersByProductType = [
  //   { tagNumbers: humanTagNumbersWithoutLocation, productType: "QR Human" },
  //   {
  //     tagNumbers: passportTagNumbersWithoutLocation,
  //     productType: "QR Passport",
  //   },
  //   { tagNumbers: genieTagNumbersWithoutLocation, productType: "QR PET" },
  //   { tagNumbers: keyTagNumbersWithoutLocation, productType: "QR Key" },
  //   { tagNumbers: bagTagNumbersWithoutLocation, productType: "QR Bag" },
  //   { tagNumbers: walletTagNumbersWithoutLocation, productType: "QR Wallet" },
  //   { tagNumbers: earphoneTagNumbersWithoutLocation, productType: "QR Gadget" },
  //   { tagNumbers: genericTagNumbersWithoutLocation, productType: "Generic" },
  // ];

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // const updateLocationForTags = async (allTagNumbers) => {
  //   // if (locationAccessDeniedRef.current) {
  //   //   // console.log("Location access has been denied. Skipping location update.");
  //   //   return;
  //   // }
  //   let latitude = null;
  //   let longitude = null;

  //   if (navigator.geolocation) {
  //     try {
  //       const position = await new Promise((resolve) => {
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             resolve({
  //               latitude: position.coords.latitude,
  //               longitude: position.coords.longitude,
  //             });
  //           },
  //           (error) => {
  //             console.error("Error retrieving location:", error);
  //             if (error.code === 1) {
  //               console.log(error.code, "User denied Geolocation");
  //               locationAccessDeniedRef.current = true;
  //               setLocationAccessDenied(true); // Update the state as well
  //             }
  //             resolve({ latitude: null, longitude: null });
  //           },
  //           { timeout: 10000 }
  //         );
  //       });

  //       latitude = position.latitude;
  //       longitude = position.longitude;

  //       if (locationAccessDeniedRef.current) {
  //         // console.log(
  //         //   "Location access has been denied. Skipping location update."
  //         // );
  //         return; // Skip API call if access was denied
  //       }

  //       if (allTagNumbers.length > 0) {
  //         try {
  //           await axios.post("/api/updateLocationForTag", {
  //             allTagNumbers,
  //             latitude,
  //             longitude,
  //           });

  //           console.log("Location updated for tags:", allTagNumbers);
  //           return allTagNumbers; // Return the updated tag numbers or process as needed
  //         } catch (error) {
  //           console.error("Error updating location:", error.message);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error retrieving location:", error.message);
  //     }
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // };

  // const fetchTagNumbers = async () => {
  //   // Wait for 10 seconds before proceeding
  //   await delay(10000);

  //   const allTagNumbers = [];

  //   for (const item of tagNumbersByProductType) {
  //     const unprocessedTags = item.tagNumbers.filter((tag) => {
  //       const isProcessed = processedTags.includes(tag);
  //       return !isProcessed;
  //     });

  //     // Push only non-empty arrays
  //     if (unprocessedTags.length > 0) {
  //       allTagNumbers.push({
  //         tagNumbers: unprocessedTags,
  //         productType: item.productType,
  //       });
  //     }
  //   }

  //   // console.log(allTagNumbers, "alltag");

  //   // Proceed if there is at least one non-empty tag category
  //   if (allTagNumbers.length > 0) {
  //     const newProcessedTags = await updateLocationForTags(allTagNumbers);

  //     setProcessedTags((prevTags) => [...prevTags]);
  //   } else {
  //     // console.log("No unprocessed tags found.");
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     humanTagNumbersWithoutLocation.length > 0 ||
  //     passportTagNumbersWithoutLocation.length > 0 ||
  //     genieTagNumbersWithoutLocation.length > 0 ||
  //     keyTagNumbersWithoutLocation.length > 0 ||
  //     bagTagNumbersWithoutLocation.length > 0 ||
  //     walletTagNumbersWithoutLocation.length > 0 ||
  //     earphoneTagNumbersWithoutLocation.length > 0 ||
  //     genericTagNumbersWithoutLocation.length > 0
  //   ) {
  //     const timerId = setTimeout(fetchTagNumbers, 10000);

  //     return () => clearTimeout(timerId); // Cleanup timeout on unmount or if dependencies change
  //   }
  // }, [
  //   humanTagNumbersWithoutLocation,
  //   passportTagNumbersWithoutLocation,
  //   genieTagNumbersWithoutLocation,
  //   keyTagNumbersWithoutLocation,
  //   bagTagNumbersWithoutLocation,
  //   walletTagNumbersWithoutLocation,
  //   earphoneTagNumbersWithoutLocation,
  //   genericTagNumbersWithoutLocation,
  //   locationAccessDenied,
  // ]);
  useEffect(() => {
    if (tagall.length > 0 && !productCategoryCalledRef.current) {
      productCategoryCalledRef.current = true;
      const tagNumbers = [];
      tagall.forEach((obj) =>
        obj.map((p) => {
          tagNumbers.push(p.tag_number);
        })
      );
      axios
        .post("/api/getAllProductCategories", {
          tag_number: tagNumbers,
        })
        .then((res) => {
          const productCategories = res.data;
          Object.keys(productCategories).forEach((tagNumber) => {
            const productCategory = productCategories[tagNumber];
            // console.log(productCategory, "category");
            switch (productCategory) {
              case "QR PET":
              case "QR Pet":
                setPet(true);
                break;
              case "QR Key":
                setKey(true);
                break;
              case "QR Bag":
              case "QR bag":
              case "Dolphin Bag":
              case "E-Bag":
              case "QR Medical":
                setBag(true);
                break;
              case "Generic":
                setGeneric(true);
                break;
              case "QR Wallet":
                setWallet(true);
                break;
              case "QR Gadget":
                setEarphone(true);
                break;
              case "QR Passport":
                setpassport(true);
                break;
              case "E-Passport":
                setpassport(true);
                break;
              case "QR Human":
                sethuman(true);
                break;
              case "QR PASSPORT":
                setpassport(true);
                break;
              case "QR HUMAN":
                sethuman(true);
                break;
              case "QR Vehicle":
                setvehicle(true)
                break;
              default:
                if (productCategory?.startsWith("Dolphin")) {
                  setBeacon(true);
                }
                break;
            }
          });
          // console.log(productCategoryCalledRef.current, "Loop madhe");
          productCategoryCalledRef.current = true;
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    const checkAllAPICallsComplete = () => {
      if (
        (human && !humanApiCalledRef.current) ||
        (passport && !passportApiCalledRef.current) ||
        (pet && !genieApiCalledRef.current) ||
        (key && !keyApiCalledRef.current) ||
        (bag && !bagApiCalledRef.current) ||
        (wallet && !walletApiCalledRef.current) ||
        (earphone && !earphoneApiCalledRef.current) ||
        (generic && !genericApiCalledRef.current) ||
        (mid && !midApiCalledRef.current) ||
        (beacon && !beaconApiCalledRef.current) ||
        (vehicle && !vehicleApiCalledRef.current)
      ) {
        // If any API call is not complete, isLoading should be true.
        // console.log("first here");
        setLoading(true);
      } else {
        // All API calls are complete.
        // console.log("second here");
        setLoading(false);
      }
    };
    // const getTagNumbersWithoutLocation = (response) => {
    //   return response
    //     .filter((item) => !item.data.latitude && !item.data.longitude) // Filter out items without lat and long
    //     .map((item) => item.data.tag_number); // Map to tag_number
    // };

    if(!vehicleApiCalledRef.current && vehicle){
      vehicleApiCalledRef.current = true
      axios.get("/api/getvehicleDashboardDetails/" + uid,{
        headers : {
          Authorization: token,
          "Content-Type": "application/json",
        }
      })
      .then((res)=>{
        const allationDates = res.data.dashboard.map((item) => item);
        const updatedAllActivationDates = vehicleallresponse.concat(
          allationDates.map((data, index) => ({
            data,
            productType: "Vehicle",
          }))
        );
        setvehicleresponse(updatedAllActivationDates)
        const inactivationDates = res.data.inactiveTags.map((item) => item);
        const updatedDeActivationDates = vehicledeactiveresponse.concat(
          inactivationDates.map((data, index) => ({
            data,
            productType: "Vehicle",
          }))
        );
        setvehicledeactive(updatedDeActivationDates);

        const lostationDates = res.data.lostTags.map((item) => item);
        const updatedLostActivationDates = vehiclelostresponse.concat(
          lostationDates.map((data, index) => ({
            data,
            productType: "Vehicle",
          }))
        );
        setvehiclelost(updatedLostActivationDates);

        const FoundDates = res.data.foundTags.map((item) => item);
        const updatedFoundDates = vehiclefoundresponse.concat(
          FoundDates.map((data, index) => ({
            data,
            productType: "Vehicle",
          }))
        );
        setvehiclefound(updatedFoundDates);

        const activationDates = res.data.activeTags.map((item) => item);
        const updatedActivationDates = vehicleactiveresponse.concat(
          activationDates.map((data, index) => ({
            data,
            productType: "Vehicle",
          }))
        );
        setvehicleactive(updatedActivationDates);
        checkAllAPICallsComplete();
      })
      
    }

    if (!humanApiCalledRef.current && human) {
      humanApiCalledRef.current = true;
      axios
        .get("/api/gethumanDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const allationDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = humanallresponse.concat(
            allationDates.map((data, index) => ({
              data,
              productType: "Human",
            }))
          );
          sethumanresponse(updatedAllActivationDates);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedAllActivationDates
          // );

          // setHumanTagNumbersWithoutLocation(tagNumbers);
          // useLocationUpdater(tagNumbers, "Human");
          // setPassportActiveResponse(res.data.activeTags);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = humandeactiveresponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Human",
            }))
          );
          sethumandeactive(updatedDeActivationDates);
          const lostationDates = res.data.lostTags.map((item) => item);
          const updatedLostActivationDates = humanlostresponse.concat(
            lostationDates.map((data, index) => ({
              data,
              productType: "Human",
            }))
          );
          sethumanlostresponse(updatedLostActivationDates);
          const FoundDates = res.data.foundTags.map((item) => item);
          const updatedFoundDates = humanfoundresponse.concat(
            FoundDates.map((data, index) => ({
              data,
              productType: "Human",
            }))
          );
          sethumanfoundreponse(updatedFoundDates);

          const activationDates = res.data.activeTags.map((item) => item);
          const updatedActivationDates = humanactivationdates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Human",
            }))
          );
          sethumanactivationdates(updatedActivationDates);
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }

    if (!passportApiCalledRef.current && passport) {
      passportApiCalledRef.current = true;
      axios
        .get("/api/getPassportDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const allationDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = PassportallResponse.concat(
            allationDates.map((data, index) => ({
              data,
              productType: "PASSPORT",
            }))
          );
          setPassportAllResponse(updatedAllActivationDates);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedAllActivationDates
          // );
          // console.log(tagNumbers, "tagNumbers");
          // setPassportTagNumbersWithoutLocation(tagNumbers);
          // setPassportActiveResponse(res.data.activeTags);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = PassportdeactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "PASSPORT",
            }))
          );
          setPassportDeactiveResponse(updatedDeActivationDates);
          const lostationDates = res.data.lostTags.map((item) => item);
          const updatedLostActivationDates = PassportlostResponse.concat(
            lostationDates.map((data, index) => ({
              data,
              productType: "PASSPORT",
            }))
          );
          setPassportLostResponse(updatedLostActivationDates);
          const FoundDates = res.data.foundTags.map((item) => item);
          const updatedFoundDates = PassportfoundResponse.concat(
            FoundDates.map((data, index) => ({
              data,
              productType: "PASSPORT",
            }))
          );
          setPassportFoundResponse(updatedFoundDates);
          const activationDates = res.data.activeTags.map((item) => item);
          const updatedActivationDates = passportActivationDates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "PASSPORT",
            }))
          );
          setPassportActivationDates(updatedActivationDates);
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }

    if (!genieApiCalledRef.current && pet) {
      genieApiCalledRef.current = true;
      axios
        .get("/api/getDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          localStorage.setItem("parent_image", res.data.parent_image_location);
          // console.log(res.data, "data response genie");
          const allationDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = allResponse.concat(
            allationDates.map((data, index) => ({
              data,
              productType: "Genie",
            }))
          );
          setAllResponse(updatedAllActivationDates);
          // setActiveResponse(res.data.activeTags);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedAllActivationDates
          // );
          // setGenieTagNumbersWithoutLocation(tagNumbers);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = deactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Genie",
            }))
          );
          setDeactiveResponse(updatedDeActivationDates);
          const foundDates = res.data.foundTags.map((item) => item);
          const updatedFoundDates = foundResponse.concat(
            foundDates.map((data, index) => ({
              data,
              productType: "Genie",
            }))
          );
          setFoundResponse(updatedFoundDates);
          const LostationDates = res.data.lostTags.map((item) => item);
          const updatedLostActivationDates = lostResponse.concat(
            LostationDates.map((data, index) => ({
              data,
              productType: "Genie",
            }))
          );
          setLostResponse(updatedLostActivationDates);
          const activationDates = res.data.activeTags.map((item) => item);
          // const productTypes = "Genie";
          const updatedActivationDates = genieActivationDates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Genie",
            }))
          );
          setGennieActivationDates(updatedActivationDates);
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }

    if (!keyApiCalledRef.current && key) {
      keyApiCalledRef.current = true;
      axios
        .get("/api/getKeyDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          // res.data.map((elm) =>
          //   localStorage.setItem(elm.tag_number, elm.key_imagelocation)
          // );
          const allactionDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = KeyallResponse.concat(
            allactionDates.map((data, index) => ({
              data,
              productType: "Key",
            }))
          );
          setKeyAllResponse(updatedAllActivationDates);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedAllActivationDates
          // );
          // setKeyTagNumbersWithoutLocation(tagNumbers);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = KeydeactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Key",
            }))
          );
          setKeyDeactiveResponse(updatedDeActivationDates);
          const lostationDates = res.data.lostTags.map((item) => item);
          const updatedLostActivationDates = KeylostResponse.concat(
            lostationDates.map((data, index) => ({
              data,
              productType: "Key",
            }))
          );
          setKeyLostResponse(updatedLostActivationDates);
          const FoundDates = res.data.foundTags.map((item) => item);
          const updatedFoundDates = KeyfoundResponse.concat(
            FoundDates.map((data, index) => ({
              data,
              productType: "Key",
            }))
          );
          setKeyFoundResponse(updatedFoundDates);
          const activationDates = res.data.activeTags.map((item) => item);
          // console.log(activationDates);
          const updatedActivationDates = keyActivationDates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Key",
            }))
          );
          // console.log(updatedActivationDates);

          setKeyActivationDates(updatedActivationDates);
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }
    // console.log(bagApiCalledRef, bag, "flag of bag");
    if (!bagApiCalledRef.current && bag) {
      bagApiCalledRef.current = true;
      axios
        .get("/api/getBagDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res, "res data bag");
          const activationDates = res.data.activeTags.map((item) => item);
          const updatedActivationDates = bagActivationDates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Bag",
            }))
          );
          setBagActivationDates(updatedActivationDates);

          const allactionDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = BagallResponse.concat(
            allactionDates.map((data, index) => ({
              data,
              productType: "Bag",
            }))
          );
          setBagAllResponse(updatedAllActivationDates);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedAllActivationDates
          // );
          // setBagTagNumbersWithoutLocation(tagNumbers);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = BagdeactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Bag",
            }))
          );
          setBagDeactiveResponse(updatedDeActivationDates);
          const LostationDates = res.data.lostTags.map((item) => item);
          const updatedLostActivationDates = BaglostResponse.concat(
            LostationDates.map((data, index) => ({
              data,
              productType: "Bag",
            }))
          );
          setBagLostResponse(updatedLostActivationDates);
          const FoundationDates = res.data.foundTags.map((item) => item);
          const updatedFoundDates = BagfoundResponse.concat(
            FoundationDates.map((data, index) => ({
              data,
              productType: "Bag",
            }))
          );
          setBagFoundResponse(updatedFoundDates);
          // res.data.map((elm) =>
          //   localStorage.setItem(elm.tag_number, elm.bag_imagelocation)
          // );
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }
    if (!walletApiCalledRef.current && wallet) {
      walletApiCalledRef.current = true;
      // console.log(walletApiCalledRef, "ref");
      // console.log(wallet, "True hai ki nahi");
      axios
        .get("/api/getwalletDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const activationDates = res.data.activeTags.map((item) => item);
          const updatedActivationDates = walletActivationDates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Wallet",
            }))
          );
          setWalletActivationDates(updatedActivationDates);
          const allactivationDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = WalletallResponse.concat(
            allactivationDates.map((data, index) => ({
              data,
              productType: "Wallet",
            }))
          );
          setWalletAllResponse(updatedAllActivationDates);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedAllActivationDates
          // );
          // setWalletTagNumbersWithoutLocation(tagNumbers);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = WalletdeactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Wallet",
            }))
          );
          setWalletDeactiveResponse(updatedDeActivationDates);
          const lostationDates = res.data.lostTags.map((item) => item);
          const updatedLostActivationDates = WalletlostResponse.concat(
            lostationDates.map((data, index) => ({
              data,
              productType: "Wallet",
            }))
          );
          setWalletLostResponse(updatedLostActivationDates);
          const foundDates = res.data.foundTags.map((item) => item);
          const updatedFoundDates = WalletfoundResponse.concat(
            foundDates.map((data, index) => ({
              data,
              productType: "Wallet",
            }))
          );
          setWalletFoundResponse(updatedFoundDates);
          // res.data.map((elm) =>
          //   localStorage.setItem(elm.tag_number, elm.pet_imagelocation)
          // );
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }
    // console.log(earphoneApiCalledRef, earphone, "flag of earphone");
    if (!earphoneApiCalledRef.current && earphone) {
      earphoneApiCalledRef.current = true;
      axios
        .get("/api/getearphoneDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // localStorage.setItem(
          //   "parent_image",
          //   res.data[0].parent_imagelocation
          // );
          // console.log(res, "res data earphone");
          const activationDates = res.data.activeTags.map((item) => item);
          const updatedActivationDates = earphoneActivationDates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Earphone",
            }))
          );
          setEarphoneActivationDates(updatedActivationDates);

          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = earphonedeactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Earphone",
            }))
          );
          setEarphoneDeactiveResponse(updatedDeActivationDates);
          const allactivationDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = earphoneallResponse.concat(
            allactivationDates.map((data, index) => ({
              data,
              productType: "Earphone",
            }))
          );
          setEarphoneAllResponse(updatedAllActivationDates);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedAllActivationDates
          // );
          // setEarphoneTagNumbersWithoutLocation(tagNumbers);
          // setEarphoneActiveResponse(res.data.activeTags);
          const lostactivationDates = res.data.lostTags.map((item) => item);
          const updatedAllLostationDates = earphonelostResponse.concat(
            lostactivationDates.map((data, index) => ({
              data,
              productType: "Earphone",
            }))
          );
          setEarphoneLostResponse(updatedAllLostationDates);
          const foundactivationDates = res.data.foundTags.map((item) => item);
          const updatedAllfoundDates = earphonefoundResponse.concat(
            foundactivationDates.map((data, index) => ({
              data,
              productType: "Earphone",
            }))
          );
          setEarphoneFoundResponse(updatedAllfoundDates);
          // console.log(res.data, "data of res");
          // res.data?.map((elm) =>
          //   localStorage.setItem(elm.tag_number, elm.pet_imagelocation)
          // );
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }
    if (!beaconApiCalledRef.current) {
      // console.log
      beaconApiCalledRef.current = true;
      axios
        .get("/api/getBeaconDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data, "res data Beacon");
          const activationDates = res.data.activeTags.map((item) => item);
          // console.log(activationDates, "BeaconactiveResponse");
          const updatedActivationDates = BeaconactiveResponse.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Beacon",
            }))
          );
          setBeaconActiveResponse(updatedActivationDates);
          // const allactionDates = res.data.dashboard.map((item) => item);
          // const updatedAllActivationDates = allactionDates.concat(
          //   BeaconallResponse.map((data, index) => ({
          //     data,
          //     productType: "Beacon",
          //   }))
          // );
          // setBeaconAllResponse(updatedAllActivationDates);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = BeacondeactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Beacon",
            }))
          );
          setBeaconDeactiveResponse(updatedDeActivationDates);
          // const LostationDates = res.data.lostTags.map((item) => item);
          // const updatedLostActivationDates = BaglostResponse.concat(
          //   LostationDates.map((data, index) => ({
          //     data,
          //     productType: "Bag",
          //   }))
          // );
          // setBagLostResponse(updatedLostActivationDates);
          // const FoundationDates = res.data.foundTags.map((item) => item);
          // const updatedFoundDates = BagfoundResponse.concat(
          //   FoundationDates.map((data, index) => ({
          //     data,
          //     productType: "Bag",
          //   }))
          // );
          // setBagFoundResponse(updatedFoundDates);
          // res.data.map((elm) =>
          //   localStorage.setItem(elm.tag_number, elm.bag_imagelocation)
          // );
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }

    if (!genericApiCalledRef.current && generic) {
      genericApiCalledRef.current = true;
      axios
        .get("/api/getGenericDashboardDetails/" + uid, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data, "res data generic");
          const activationDates = res.data.activeTags.map((item) => item);
          const updatedActivationDates = genericActivationDates.concat(
            activationDates.map((data, index) => ({
              data,
              productType: "Generic",
            }))
          );
          setGenericActivationDates(updatedActivationDates);
          // const tagNumbers = getTagNumbersWithoutLocation(
          //   updatedActivationDates
          // );
          // setGenericTagNumbersWithoutLocation(tagNumbers);
          const allactivationDates = res.data.dashboard.map((item) => item);
          const updatedAllActivationDates = GenericResponse.concat(
            allactivationDates.map((data, index) => ({
              data,
              productType: "Generic",
            }))
          );
          setGenericResponse(updatedAllActivationDates);
          // setGenericActiveResponse(res.data.activeTags);
          const inactivationDates = res.data.inactiveTags.map((item) => item);
          const updatedDeActivationDates = GenericdeactiveResponse.concat(
            inactivationDates.map((data, index) => ({
              data,
              productType: "Generic",
            }))
          );
          setGenericDeactiveResponse(updatedDeActivationDates);
          const inlostationDates = res.data.lostTags.map((item) => item);
          const updatedLostActivationDates = GenericlostResponse.concat(
            inlostationDates.map((data, index) => ({
              data,
              productType: "Generic",
            }))
          );
          setGenericLostResponse(updatedLostActivationDates);
          const infoundationDates = res.data.foundTags.map((item) => item);
          const updatedFoundActivationDates = GenericfoundResponse.concat(
            infoundationDates.map((data, index) => ({
              data,
              productType: "Generic",
            }))
          );
          setGenericFoundResponse(updatedFoundActivationDates);
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }

    if (!midApiCalledRef.current && mid != null) {
      midApiCalledRef.current = true;
      //SQL_Close
      // axios
      //   .get("/api/getFoundLftags/" + mid, {
      //     headers: {
      //       Authorization: token,
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then((res) => {
      //     const data = res.data?.data || [];
      //     const activationDates =
      //       data.length > 0 ? data.map((item) => item) : [];
      //     const updatedActivationDates = oldTagsfoundResponse.concat(
      //       activationDates.map((data, index) => ({
      //         data,
      //         productType: "LNF",
      //       }))
      //     );
      //     setOldTagsFoundResponse(updatedActivationDates);
      //   })
      //   .catch((error) => {
      //     console.error(error.message);
      //   });
      //SQL_Close
      // axios
      //   .get(`/api/getDeactiveLftags/${mid}`, {
      //     headers: {
      //       Authorization: token,
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then((res) => {
      //     // console.log(res.data, "deta deactive");
      //     if (res.data != null) {
      //       const activationDates = res.data.map((item) => item);
      //       const updatedActivationDates = oldTagsdeactiveResponse.concat(
      //         activationDates.map((data, index) => ({
      //           data,
      //           productType: "LNF",
      //         }))
      //       );
      //       setOldTagsDeactiveResponse(updatedActivationDates);
      //       // getOldDeactivatetags(res.data);

      //       // console.log(" Deactivate res.data--->", res.data);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error.message);
      //   });
      //SQL_Close
      // axios
      //   .get(`/api/getReportLostLftags/${mid}`, {
      //     headers: {
      //       Authorization: token,
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then((res) => {
      //     // console.log(res.data, "deta lost");
      //     if (res.data != null) {
      //       const activationDates = res.data.map((item) => item);
      //       const updatedActivationDates = oldTagslostResponse.concat(
      //         activationDates.map((data, index) => ({
      //           data,
      //           productType: "LNF",
      //         }))
      //       );
      //       setoldTagslostResponse(updatedActivationDates);
      //       // getoldTagslost(res.data);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error.message);
      //   });

      axios
        .get(`/api/getUsrMapData/${mid}`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(mid, "modddd ");
          if (res.data != null) {
            var userId = res.data[0]?.userId;
            // console.log(userId, "IDDEE");
            //SQL_Close
            // if (userId) {
            //   axios
            //     .get(`/api/getAllLFtags/${res.data[0].userId}`, {
            //       headers: {
            //         Authorization: token,
            //         "Content-Type": "application/json",
            //       },
            //     })
            //     .then((res) => {
            //       // console.log(res.data, "resddd lnf");
            //       const activationDates = res.data.data.map((item) => item);
            //       const updatedActivationDates = OldtagResponse.concat(
            //         activationDates.map((data, index) => ({
            //           data,
            //           productType: "LNF",
            //         }))
            //       );
            //       // console.log(updatedActivationDates, "ALLLLL tagaaa");
            //       setOldtagResponse(updatedActivationDates);
            //       // getLFtags(res.data.data);

            //       if (res.data.data != null) {
            //         res.data.data.map((elm) => {
            //           // console.log("elm--->", elm);
            //           // console.log(userId, "iddddd");
            //           if (elm.itemName === "Passport") {
            //             updatePsprtDetails(userId);
            //           }
            //         });
            //       }
            //     })
            //     .catch((error) => {
            //       console.error(error.message);
            //     });
            // }
          }
          checkAllAPICallsComplete();
        })
        .catch((error) => {
          console.error(error.message);
          checkAllAPICallsComplete();
        });
    }
    // console.log(PassportdeactiveResponse, "activ");
    // console.log(earphoneActivationDates, "wallet");
    setAllActivationDates([
      ...vehicleactiveresponse,
      ...passportActivationDates,
      ...genieActivationDates,
      ...keyActivationDates,
      ...bagActivationDates,
      ...walletActivationDates,
      ...genericActivationDates,
      ...earphoneActivationDates,
      ...humanactivationdates,
      ...BeaconactiveResponse,
      ...OldtagResponse,
    ]);
    setAllDeactivationDates([
      ...vehicledeactiveresponse,
      ...PassportdeactiveResponse,
      ...deactiveResponse,
      ...KeydeactiveResponse,
      ...BagdeactiveResponse,
      ...WalletdeactiveResponse,
      ...GenericdeactiveResponse,
      ...earphonedeactiveResponse,
      ...humandeactiveresponse,
      ...BeacondeactiveResponse,
      ...oldTagsdeactiveResponse,
    ]);
    setAllDates([
      ...vehicleallresponse,
      ...allResponse,
      ...PassportallResponse,
      ...BagallResponse,
      ...KeyallResponse,
      ...WalletallResponse,
      ...GenericResponse,
      ...earphoneallResponse,
      ...humanallresponse,
      // ...BeaconallResponse,
      ...OldtagResponse,
    ]);
    setLostDates([
      ...vehiclelostresponse,
      ...lostResponse,
      ...PassportlostResponse,
      ...BaglostResponse,
      ...KeylostResponse,
      ...WalletlostResponse,
      ...GenericlostResponse,
      ...earphonelostResponse,
      ...humanlostresponse,
      ...oldTagslostResponse,
    ]);
    setFoundDates([
      ...vehiclefoundresponse,
      ...foundResponse,
      ...PassportfoundResponse,
      ...BagfoundResponse,
      ...KeyfoundResponse,
      ...WalletfoundResponse,
      ...GenericfoundResponse,
      ...earphonefoundResponse,
      ...humanfoundresponse,
      ...oldTagsfoundResponse,
    ]);
    setApiCallMade(true);
  }, [
    tagall,
    apiCallMade,
    bag,
    vehicle,
    pet,
    key,
    generic,
    wallet,
    earphone,
    human,
    passport,
    beacon,
    mid,
    passportActivationDates,
    PassportdeactiveResponse,
    genieActivationDates,
    deactiveResponse,
    keyActivationDates,
    KeydeactiveResponse,
    bagActivationDates,
    BagdeactiveResponse,
    walletActivationDates,
    WalletdeactiveResponse,
    genericActivationDates,
    GenericdeactiveResponse,
    earphoneActivationDates,
    earphonedeactiveResponse,
    allResponse,
    PassportallResponse,
    BagallResponse,
    KeyallResponse,
    WalletallResponse,
    GenericResponse,
    earphoneallResponse,
    lostResponse,
    PassportlostResponse,
    BaglostResponse,
    KeylostResponse,
    WalletlostResponse,
    GenericlostResponse,
    earphonelostResponse,
    foundResponse,
    PassportfoundResponse,
    BagfoundResponse,
    KeyfoundResponse,
    WalletfoundResponse,
    GenericfoundResponse,
    earphonefoundResponse,
    humanactivationdates,
    humandeactiveresponse,
    humanlostresponse,
    humanallresponse,
    humanfoundresponse,
    BeaconactiveResponse,
    BeacondeactiveResponse,
    OldtagResponse,
    oldTagslostResponse,
    oldTagsdeactiveResponse,
    oldTagsfoundResponse,
    vehicleallresponse,
    vehicledeactiveresponse,
    vehiclelostresponse,
    vehiclefoundresponse,
    vehicleactiveresponse
  ]);
  useEffect(() => {
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmm')
    if (apiCallMade) {
      productCategoryCalledRef.current = false;
    }
  }, [apiCallMade]);
  useEffect(() => {}, [
    allActivationDates,
    allDeactivationDates,
    allactivationDates,
    lostDates,
    foundDates,
  ]);

  const componentMap = {
    Vehicle : Activevehiclecard,
    Human: Humancard,
    Active: ActiveCard,
    Key: ActiveKeyCard,
    PASSPORT: ActivePassportcard,
    Genie: ActiveCard,
    Bag: ActiveBagCard,
    Wallet: ActiveWalletcard,
    Beacon: ActiveTrackercard,
    Earphone: ActiveEarphoneCard,
    Generic: ActiveGenericcard,
    LNF: OldTagsCard,
  };

  const RenderComponents = () => {
    if (alignment === "left") {
      // console.log(allActivationDates, "Dates Of activation");

      allActivationDates.sort(
        (a, b) =>
          new Date(b.data.activation_date) - new Date(a.data.activation_date)
      );

      if (allActivationDates.length === 0) {
        return (
          <p className="fade-in-text">
            Currently, there are no active tags. To Activate
          </p>
        );
      }

      return (
        <>
          {allActivationDates.map((item, index) => {
            const Component = componentMap[item.productType];
            if (Component) {
              return <Component key={index} {...item.data} />;
            }
            return null;
          })}
        </>
      );
    } else {
      allDeactivationDates.sort(
        (a, b) =>
          new Date(b.data?.activation_date) - new Date(a.data?.activation_date)
      );
      if (allDeactivationDates.length === 0) {
        return (
          <p className="fade-in-text">Currently, there are no inactive tags.</p>
        );
      }

      return (
        <>
          {allDeactivationDates.map((item, index) => {
            const Component = componentMap[item.productType];
            if (Component) {
              return <Component key={index} {...item.data} />;
            }
            return null;
          })}
        </>
      );
    }
  };

  const Rendercomponentsone = () => {
    allactivationDates.sort(
      (a, b) =>
        new Date(b.data.activation_date) - new Date(a.data.activation_date)
    );
    if (allactivationDates.length === 0) {
      return <p className="fade-in-text">Currently, there are no all tags.</p>;
    }
    return (
      <>
        {allactivationDates.map((item, index) => {
          const Component = componentMap[item.productType];
          if (Component) {
            return <Component key={index} {...item.data} />;
          }
          return null;
        })}
      </>
    );
  };
  const SelectedToggle1 = () => {
    if (alignment === "left") {
      lostDates.sort(
        (a, b) =>
          new Date(b.data.activation_date) - new Date(a.data.activation_date)
      );
      if (lostDates.length === 0) {
        return (
          <p className="fade-in-text">
            Currently, there are no reported lost tags.
          </p>
        );
      }

      return (
        <>
          {lostDates.map((item, index) => {
            const Component = componentMap[item.productType];
            if (Component) {
              return <Component key={index} {...item.data} />;
            }
            return null;
          })}
        </>
      );
    } else {
      foundDates.sort(
        (a, b) =>
          new Date(b.data.activation_date) - new Date(a.data.activation_date)
      );
      if (foundDates.length === 0) {
        return (
          <p className="fade-in-text">
            Currently, there are no reported found tags.
          </p>
        );
      }
      return (
        <>
          {foundDates.map((item, index) => {
            const Component = componentMap[item.productType];
            if (Component) {
              return <Component key={index} {...item.data} />;
            }
            return null;
          })}
        </>
      );
    }
  };

  function CheckLfTag(tagNo) {
    axios
      .get(
        "/api/getLftagsInfoNumber/" + tagNo,
        {},
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        //console.log("res.data--->",res.data);
        // console.log("res.message--->", res);
        if (res.message === "Invalid Tag") {
          setShow(false);
          setTagNotFound(true);
        }
        if (res.data.length > 0) {
          //CheckLfTag(state.tag_number); /activate-lftag/
          // console.log("CALL THE API");
          window.location.href = "/#/activate-lftag/" + tagNumber;
        } else {
          setShow(false);
          setTagNotFound(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        setShow(false);
        setTagNotFound(true);
      });
  }

  function activateTag() {
  const state = {
    tag_number: activatetag.toUpperCase(),
  };
  setShowLoader(true);

  const loaderTimer = setTimeout(() => {
    setShowLoader(false);
  }, 5000);

  axios
    .post("/api/checkTagNumberWithoutHeader", state, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const productcategory = res.data.tag_data.product_category;

      // Check for "Dolphin" or "Smart" categories
      if (
        productcategory.startsWith("Dolphin") || 
        productcategory.startsWith("Smart")
      ) {
        setWalletDialogOpen(true);
        return;
      }

      // Clear session storage
      sessionStorage.removeItem("tag_number");

      // Route based on product category
      const routes = {
        "QR PET": `/pet-activation/${activatetag}`,
        "QR Key": `/key-activation/${activatetag}`,
        "QR bag": `/bag-activation/${activatetag}`,
        "QR Bag": `/bag-activation/${activatetag}`,
        "E-Bag": `/bag-activation/${activatetag}`,
        "QR Medical": `/bag-activation/${activatetag}`,
        "QR Passport": `/passport-activation/${activatetag}`,
        "E-Passport": `/passport-activation/${activatetag}`,
        "QR Gadget": `/earphone-activation/${activatetag}`,
        "Generic": `/generic-activation/${activatetag}`,
        "QR Wallet": `/wallet-activation/${activatetag}`,
        "QR Human": `/human-activation/${activatetag}`,
        "QR HUMAN": `/human-activation/${activatetag}`,
        "QR Vehicle": `${vehicle_frontned}/#/vehicle-activation/${activatetag}`,
      };

      if (routes[productcategory]) {
        window.location.href = routes[productcategory];
      } else {
        console.warn("Unknown product category:", productcategory);
      }
    })
    .catch((error) => {
      clearTimeout(loaderTimer);
      setShowLoader(false);

      const responseData = error.response?.data;
      console.log(responseData,"responseData")
      if (responseData === "Tag not found by the specified number.") {
        setTagNotFound(true);
        // setAlertState({ ...alertState, tagNotFound: true });
        CheckLfTag(state.tag_number);
      } else if (responseData === "Tag is already activated.") {
        // setShow(false);
        setAlreadyActivated(true);
        // setAlertState({ ...alertState, alreadyActivated: true });
      } else {
        console.error("Unexpected error:", error.response || error);
      }
    });
}


  // const showSidebar = (e) => {
  //   e.preventDefault();

  //   setSidebar(!sidebar);
  // };
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const showSubMenu = (e) => {
    e.preventDefault();

    setSubMenu(!subMenu);
  };
  // const handleRetry = () => {
  //   setShowLocationErrorModal(false);
  //   getLocation();
  //   // Retry the location retrieval logic if needed
  //   // You can call getLocation() again here if desired
  // };
  const walletDialogHandleClose = () => {
    setWalletDialogOpen(false);
  };
  // console.log(showLoader, "loader");
  return (
    <>
      {concentform && !userdetailsmodal && <Consentform />}
      {userdetailsmodal ? (
        <Userdetailscomponent
          userdetailsmodal={userdetailsmodal}
          userdetails={userdetails}
          setuserdetailsmodal={setuserdetailsmodal}
        />
      ) : null}

      <Navbar />
      {loading ? (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`dashboard-main-wrapper ${
            sidebarOpen ? "sidebar-open" : ""
          }`}
        >
          
          <div className="dashboard-left-wrapper">
            <Sidebar
              open={sidebarOpen}
              onClose={handleSidebarToggle}
              handleShow={handleShow}
              handleAllTags={handleAllTags}
              handleActiveInactive={handleActiveInactive}
              handleLostFound={handleLostFound}
              showNotifications={true}
            />
            <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => setshowQR(true)}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={activatetag}
                    onChange={(e) => {
                      const inputValue = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase()
                        .slice(0, 10);
                      setActivateTag(inputValue);
                    }}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="dashboard-verifyOtp-button"
                    onClick={activateTag}
                  >
                    ACTIVATE
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          <Dialog
        PaperProps={{
              style: { borderRadius: 15, padding: '1.5em',position: 'relative' },
            }}
        open={walletDialogOpen}
        onClose={walletDialogHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <IconButton
                aria-label="close"
                onClick={walletDialogHandleClose}
                style={{
                  position: 'absolute !impotant' ,
                  top: '10px !impotant',
      right: '10px !impotant',
      zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  }><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  }>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions>
      </Dialog>
     
            {/* <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={tagNotFound}
              onClose={handleNotFound}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent className="small__popup">
                <h5 style={{ textAlign: "center" }}>Tag number not found</h5>
                 <IconButton
                aria-label="close"
                onClick={handleNotFound}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                {/* <DialogActions>
                  <button className="small_pop_btn" onClick={handleNotFound}>
                    OK
                  </button>
                </DialogActions> */}
              {/* </DialogContent>
            </Dialog> */} 

            {/* <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={alreadyActivated}
              onClose={handleActivated}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent className="small__popup">
                <h5 style={{ textAlign: "center" }}>
                  Tag is already activated
                </h5>
                 <IconButton
                aria-label="close"
                onClick={handleActivated}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <button className="small_pop_btn" onClick={handleActivated}>
                  OK
                </button>
              </DialogContent>
            </Dialog> */}
       {tagNotFound && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setTagNotFound(false)}
            anchorOrigin={{ vertical: "top", horizontal:  "center", }}
            sx={{
              marginTop: "580px", // Optionally add margin-top if needed
              width:"100%"
            }}
          >
          <Alert severity="warning" onClose={() => setTagNotFound(false)} sx={{
            height: '50px', // Increase height of the Alert
            // width: '100%', // Ensures Alert takes full width of the Snackbar
            fontSize: '1.2rem', // Optionally adjust font size for better readability
          }}>
              Tag not found!
            </Alert>
          </Snackbar>
        )}

        {alreadyActivated && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setAlreadyActivated(false)}
            anchorOrigin={{
                      vertical: 'top', // Positioning vertically at the top
                      horizontal:  "center", // Positioning horizontally at the right
                    }}
                    sx={{
                      marginTop: '580px', // Optionally add margin-top if needed
                      width:"100%"
                    }}
          >
            <Alert severity="info" onClose={() => setAlreadyActivated(false)} sx={{
                height: '50px', // Increase height of the Alert
                // width: '100%', // Ensures Alert takes full width of the Snackbar
                fontSize: '1.2rem', // Optionally adjust font size for better readability
              }}>
              Tag is already activated.
            </Alert>
          </Snackbar>
        )}

          </div>

          {activeInactive ? (
            <div className="dashboard-right-pannel">
              <ToggleButtonGroup
                className="tbuttonsgroup"
                value={alignment}
                onChange={handleAlignment}
                aria-label="text alignment"
                style={{ width: "100%" }}
              >
                <ToggleButton
                  className="tbuttons"
                  value="left"
                  aria-label="left aligned"
                  style={{
                    width: "100%",
                    backgroundColor:
                      alignment === "left"
                        ? "#FCBC1B"
                        : "rgba(231, 227, 227, 0.6)",
                    color: "black",
                    fontWeight: 400,
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  ACTIVE
                </ToggleButton>

                <ToggleButton
                  className="tbuttons"
                  value="right"
                  aria-label="centered"
                  style={{
                    width: "100%",
                    backgroundColor:
                      alignment === "left"
                        ? "rgba(231, 227, 227, 0.6)"
                        : "#FCBC1B",
                    color: "black",
                    fontWeight: 400,
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  INACTIVE
                </ToggleButton>
                <br />
              </ToggleButtonGroup>
              <br />
              <br />

              <div>
                {loading ? (
                  <div className="loader-container">
                    <div className="loader">
                      <img
                        src={
                          "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                        }
                        alt="Loading..."
                        loading="lazy"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {alignment === "left" ? (
                      <>
                        {memberslist?.length
                          ? memberslist?.map((item, index) => {
                              return (
                                <Dolphincard
                                  key={index}
                                  props={item}
                                  location={JSON.parse(item?.locationSetting)}
                                  userid={item?.RequestUserId}
                                />
                              );
                            })
                          : null}
                      </>
                    ) : null}
                    <RenderComponents />
                  </>
                )}
                <br />
              </div>
            </div>
          ) : null}

          {allTags ? (
            <div className="dashboard-right-pannel">
              <ToggleButtonGroup
                className="tbuttonsgroup alltbuttongrp"
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                style={{ width: "100%" }}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  style={{
                    width: "100%",
                    backgroundColor: "#FCBC1B",
                    fontWeight: 400,
                    color: "black",
                    borderRadius: "10px",
                  }}
                >
                  All Tags
                </ToggleButton>
                <br />
              </ToggleButtonGroup>
              <br />
              <br />

              <div className="align-div">
                <>
                  {memberslist?.length
                    ? memberslist?.map((item, index) => {
                        return (
                          <Dolphincard
                            key={index}
                            props={item}
                            location={JSON.parse(item?.locationSetting)}
                            userid={item?.RequestUserId}
                          />
                        );
                      })
                    : null}
                  <Rendercomponentsone />
                </>
                <br />
              </div>
            </div>
          ) : null}

          {lostFound ? (
            <div className="dashboard-right-pannel">
              <ToggleButtonGroup
                className="tbuttonsgroup alltbuttongrp"
                value={alignment}
                onChange={handleAlignment}
                aria-label="text alignment"
                style={{ width: "100%" }}
              >
                <ToggleButton
                  className="tbuttons"
                  value="left"
                  aria-label="left aligned"
                  style={{
                    width: "100%",
                    backgroundColor:
                      alignment === "left"
                        ? "#FCBC1B"
                        : "rgba(231, 227, 227, 0.6)",
                    fontWeight: 400,
                    color: "black",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  LOST
                </ToggleButton>

                <ToggleButton
                  className="tbuttons"
                  value="right"
                  aria-label="centered"
                  style={{
                    width: "100%",
                    backgroundColor:
                      alignment === "left"
                        ? "rgba(231, 227, 227, 0.6)"
                        : "#FCBC1B",
                    fontWeight: 400,
                    color: "black",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  FOUND
                </ToggleButton>
                <br />
              </ToggleButtonGroup>

              <br />
              <br />

              <div>
                {loading ? (
                  <div className="loader-container">
                    <div className="loader">
                      <img
                        src={
                          "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                        }
                        alt="Loading..."
                        loading="lazy"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <SelectedToggle1 />
                  </>
                )}
                <br />
              </div>
            </div>
          ) : null}
          {showLoader && (
            <div className="loader-container">
              <div className="loader">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                  }
                  alt="Loading..."
                  loading="lazy"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
          )}

          {showtag && (
            <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={showtag}
              fullWidth
              maxWidth="xs"
              onClose={handleCloseTag}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    value={activatetag}
                    onChange={handleTagNumberChange}
                    placeholder="Owner ID"
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="dashboard-verifyOtp-button"
                    onClick={activateTag}
                  >
                    ACTIVATE
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          )}
          {/* <LocationErrorModal
            open={showLocationErrorModal}
            handleClose={() => setShowLocationErrorModal(false)}
            handleRetry={handleRetry}
          /> */}
          {renderQRModal()}
        </div>
      )}
    </>
  );
}

export default Dashboard;
