import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from '@mui/material/Button';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Snackbar, Alert } from "@mui/material";
// import { Menu,  } from "@mui/material";
import Sidebar from "../sidebar/sidebar.component.jsx";
import "./notifications.component.css";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import { Select, FormControl, InputLabel } from "@mui/material";
import * as FcIcons from "react-icons/fc";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
// import CatDog from "../../assets/catdog1.jpg";
// import Mapimage from "../../assets/map.png";
// import Alert from "@mui/material/Alert";
// import {Snackbar} from "@mui/material"
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { QrReader } from "react-qr-reader";
// import search from "../../assets/search.png";
// import qrcode from "../../assets/qr-code.png";
import { switchUrls } from "../../api/index.js";
const vehicle_frontned = switchUrls("vehicle_frontend");

const baseURL = switchUrls("gateway");
function Notifications() {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  let usertoken = readCookie("token");
  const token = usertoken;
  const Name = capitalizeName(localStorage.getItem("name"));
  const [productcategory, setProductcategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(""); // New state to track selected category
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [tagNumber, setTagNumber] = useState("");
  const [show, setShow] = useState(false);
  const [activatetag, setActivateTag] = useState("");
  const [showtag, setShowTag] = useState(false);
  const [tagNotFound, setTagNotFound] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNotFound = () => setTagNotFound(false);
  const handleActivated = () => setAlreadyActivated(false);
  const [scanCount, setScanCount] = useState("");
  const [scancount1, setscancount1] = useState("");
  const [scancount2, setscancount2] = useState(0);
  const [founderCount, setFounderCount] = useState(0);
  const [scanneddetails, setscanneddetails] = useState(null);
  const [scanmedical, setscanmedical] = useState(null);
  const [founderDetails, setFounderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [alignment, setAlignment] = React.useState("view-scanned-details");
  const [productCategories, setProductCategories] = useState([]);
  const [scanData, setScanData] = useState([]);
  const [founderData, setFounderData] = useState([]);
  const [scanMedicalByCategory, setScanMedicalByCategory] = useState(null);
  const [scanCountByCategory, setScanCountByCategory] = useState(" ");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenu, setSubMenu] = useState(true);
  const [sidebar, setSidebar] = useState(true);
  const [showQR, setshowQR] = useState(false);
  const [loader, setloader] = useState(false);
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);
  const [vectorimg, setvectorimg] = useState({
    Cat: "https://storage.googleapis.com/pettag/qr/assets/cat.png",
    Dog: "https://storage.googleapis.com/pettag/qr/assets/dog.png",
  });
  const address ="Unnamed Road, Pahra, Bihar 847230, India,268, Railway Chawl, Parel, Mumbai, Maharashtra 400012, India, 113, Marol MIDC Industry Estate, Andheri East, Mumbai, Maharashtra 400047, India"
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlemapclick = (e) => {
    e.preventDefault();
    window.location.href = `https://www.google.com/maps/search/?api=1&query=" +
                                  res.latitude +
                                  "," +
                                  res.longitude`;
  };
  const handleCloseTag = () => {
    setShow(false);
  };
  const handleTagNumberChange = (e) => {
    // console.log(e.target.value, "Valuesss");
    const value = e.target.value.toUpperCase();
    sessionStorage.getItem("tag_number", value);
    // setTagNumber(value);
    // setActivateTag(value);
  };

  useEffect(() => {
    if (tagNumber) {
      axios
        .get("/api/getTagNumber", {
          params: {
            tag: tagNumber,
          },
        })
        .then((res) => {
          var data = res.data.tagResult.product_category;
          // console.log(data, "resshhshh");
          setProductcategory(data);
          // showinitialletter(data);
        })
        .catch((error) => {});
    }
  });
  useEffect(() => {
    axios
      .get("/api/getProductCategories")
      .then((response) => {
        // console.log(response,"response")
        setProductCategories(response.data); // Set the product categories array
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
      });
      // console.log(productCategories);
  }, []); 
  const renderQRModal = () => {
    return (
      <Dialog
        open={showQR}
        fullWidth
        maxWidth="xs"
        onClose={() => setshowQR(false)}
        aria-labelledby={"Scan QR"}
      >
        <div>
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setTagNumber(
                  result?.text.split("/")[result?.text.split("/").length - 1]
                );
                setshowQR(false);
              }

              if (error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </Dialog>
    );
  };

  function join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  const month = [{ month: "short" }, { year: "numeric" }];
  const day = [{ day: "numeric" }];
  const weekday = [{ weekday: "short" }];

  useEffect(() => {
    setloader(true);
    axios
      .get(`${baseURL}/api/getscanlocation/` + localStorage.getItem("uid"), {
        headers: { Authorization: token },
      })
      .then((response) => {
        // console.log(response, "response");
      
        setscancount1(response.data.scan_count);
        setFounderCount(response.data.founder_count);

        if (response.data.scan_count > 0) {
          setscanneddetails(response.data.data.scanDetails);
        }
        if (response.data.founder_count > 0) {

          setFounderDetails(response.data.data.founderDetails);
        }
        if (response.data.message) {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        setloader(false)
        console.error(error.message);
      });

    axios
      .get("/api/getReportFoundUser/" + localStorage.getItem("uid"), {
        headers: { Authorization: token },
      })
      .then((response) => {
        setloader(false)
        setscancount2(response.data.scan_count);
        if (response.data.scan_count > 0) {
          setscanmedical(response.data.data);
        }
      })
      .catch((error) => {
        setloader(false);
        console.error(error.message);
      });
  }, []);
  function CheckLfTag(tagNo) {
    axios
      .get(
        "/api/getLftagsInfoNumber/" + tagNo,
        {},
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        //console.log("res.data--->",res.data);
        // console.log("res.message--->", res);
        if (res.message === "Invalid Tag") {
          setShow(false);
          setTagNotFound(true);
        }
        if (res.data.length > 0) {
          //CheckLfTag(state.tag_number); /activate-lftag/
          // console.log("CALL THE API");
          window.location.href = "/#/activate-lftag/" + tagNumber;
        } else {
          setShow(false);
          setTagNotFound(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        setShow(false);
        setTagNotFound(true);
      });
  }
    function activateTag() {
  const state = {
    tag_number: activatetag.toUpperCase(),
  };
  setloader(true);

  const loaderTimer = setTimeout(() => {
    setloader(false);
  }, 5000);

  axios
    .post("/api/checkTagNumberWithoutHeader", state, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const productcategory = res.data.tag_data.product_category;

      // Check for "Dolphin" or "Smart" categories
      if (
        productcategory.startsWith("Dolphin") || 
        productcategory.startsWith("Smart")
      ) {
        setWalletDialogOpen(true);
        return;
      }

      // Clear session storage
      sessionStorage.removeItem("tag_number");

      // Route based on product category
      const routes = {
        "QR PET": `/pet-activation/${activatetag}`,
        "QR Key": `/key-activation/${activatetag}`,
        "QR bag": `/bag-activation/${activatetag}`,
        "QR Bag": `/bag-activation/${activatetag}`,
        "E-Bag": `/bag-activation/${activatetag}`,
        "QR Medical": `/bag-activation/${activatetag}`,
        "QR Passport": `/passport-activation/${activatetag}`,
        "E-Passport": `/passport-activation/${activatetag}`,
        "QR Gadget": `/earphone-activation/${activatetag}`,
        "Generic": `/generic-activation/${activatetag}`,
        "QR Wallet": `/wallet-activation/${activatetag}`,
        "QR Human": `/human-activation/${activatetag}`,
        "QR HUMAN": `/human-activation/${activatetag}`,
        "QR Vehicle": `${vehicle_frontned}/#/vehicle-activation/${activatetag}`,
      };

      if (routes[productcategory]) {
        window.location.href = routes[productcategory];
      } else {
        console.warn("Unknown product category:", productcategory);
      }
    })
    .catch((error) => {
      clearTimeout(loaderTimer);
      setloader(false);

      const responseData = error.response?.data;

      if (responseData === "Tag not found by the specified number.") {
        setTagNotFound(true);
        CheckLfTag(state.tag_number);
      } else if (responseData === "Tag is already activated.") {
        setShow(false);
        setAlreadyActivated(true);
      } else {
        console.error("Unexpected error:", error.response || error);
      }
    });
  } 
  const fetchFoundUserByCategory  = (category) => {
    // console.log(category, "Fetching data for category");

    // Show loader before making the API request
    // setLoading(true);
    setScanMedicalByCategory(null); // Clear previous category data
    setscancount2("0");
    setSelectedCategory(category);

    axios
        .get(`/api/getReportFoundUserByCategory/${localStorage.getItem("uid")}`, {
            params: { product_category: category },
            headers: { Authorization: token },
        })
        .then((response) => {
            // setLoading(false);1
            // console.log(response.data.scan_count, "response data for category");

            if (response.data.scan_count > 0) {
                setScanMedicalByCategory(response.data.data);
                setscancount2(response.data.scan_count);
            } else {
                // console.log("here")
                setScanMedicalByCategory(null);
                setscancount2("0");
            }
        })
        .catch((error) => {
            // setLoading(false);
            setScanMedicalByCategory(null);
            setscancount2("0");
            console.error("Error fetching category-specific data:", error.message);
        });
};

  // const showSidebar = (e) => {
  //   e.preventDefault();

  //   setSidebar(!sidebar);
  // };
  const showSubMenu = (e) => {
    e.preventDefault();

    setSubMenu(!subMenu);
  };
  const fetchScansByCategory = (category) => {
    // console.log(category, "category");

    // Reset previous data before fetching new data
    setScanData(null);
    setScanCount("0");
    setFounderData(null);
    setFounderCount("0");
    setSelectedCategory(category);

    axios
        .get(`/api/getScanLocationProduct/${localStorage.getItem("uid")}`, {
            params: { product_category: category },
        })
        .then((response) => {
            // console.log(response, "response mail");
            // console.log(response.data.scan_count, "response");

            const { scanDetails, founderDetails } = response.data.data;

            if (scanDetails.length > 0) {
                setScanData(scanDetails);
                setScanCount(response.data.scan_count);
            } else {
                setScanData(null);
                setScanCount("0");
            }

            if (founderDetails.length > 0) {
                setFounderData(founderDetails);
                setFounderCount(response.data.founder_count);
            } else {
                setFounderData(null);
                setFounderCount("0");
            }

            setSnackbarMessage(response.data.message || "Scan details retrieved successfully.");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        })
        .catch((error) => {
            // console.log("Error fetching scans", error);
            setScanData(null);
            setScanCount("0");
            setFounderData(null);
            setFounderCount("0");
            setSnackbarMessage(error.response?.data?.message || "Failed to fetch scan details.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
};

  function handleLostFound() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }

  function handleAllTags() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }

  function handleActiveInactive() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }
   const walletDialogHandleClose = () => {
    setWalletDialogOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the dropdown menu
  const handleClosedropdown = () => {
    setAnchorEl(null);
  };
  // console.log(founderDetails,"details")
  // console.log(selectedCategory,"datta")
  return (
    <>
      <Navbar />

      <div className="notifications-main-wrapper">
        <div className="editPetDetails-left-wrapper">
          <Sidebar
            open={sidebarOpen}
            onClose={handleSidebarToggle}
            handleShow={handleShow}
            handleAllTags={handleAllTags}
            handleActiveInactive={handleActiveInactive}
            handleLostFound={handleLostFound}
            showNotifications={false}
          />
          <Dialog
              PaperProps={{
                style: { borderRadius: 15, padding: '1em' },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => setshowQR(true)}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={activatetag}
                    onChange={(e) => {
                      const inputValue = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase()
                        .slice(0, 10);
                      setActivateTag(inputValue);
                    }}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="dashboard-verifyOtp-button"
                    onClick={activateTag}
                  >
                    ACTIVATE
                  </button>
                </div>
              </DialogContent>
            </Dialog>
             <Dialog
        PaperProps={{
              style: { borderRadius: 15, padding: '1.5em',position: 'relative' },
            }}
        open={walletDialogOpen}
        onClose={walletDialogHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <IconButton
                aria-label="close"
                onClick={walletDialogHandleClose}
                style={{
                  position: 'absolute !impotant' ,
                  top: '10px !impotant',
      right: '10px !impotant',
      zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  }><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  }>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions>
      </Dialog>
       {tagNotFound && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setTagNotFound(false)}
            anchorOrigin={{ vertical: "top", horizontal:  "center", }}
            sx={{
              marginTop: "580px", // Optionally add margin-top if needed
              width:"100%"
            }}
          >
          <Alert severity="warning" onClose={() => setTagNotFound(false)} sx={{
            height: '50px', // Increase height of the Alert
            // width: '100%', // Ensures Alert takes full width of the Snackbar
            fontSize: '1.2rem', // Optionally adjust font size for better readability
          }}>
              Tag not found!
            </Alert>
          </Snackbar>
        )}

        {alreadyActivated && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setAlreadyActivated(false)}
            anchorOrigin={{
                      vertical: 'top', // Positioning vertically at the top
                      horizontal:  "center", // Positioning horizontally at the right
                    }}
                    sx={{
                      marginTop: '580px', // Optionally add margin-top if needed
                      width:"100%"
                    }}
          >
            <Alert severity="info" onClose={() => setAlreadyActivated(false)} sx={{
                height: '50px', // Increase height of the Alert
                // width: '100%', // Ensures Alert takes full width of the Snackbar
                fontSize: '1.2rem', // Optionally adjust font size for better readability
              }}>
              Tag is already activated.
            </Alert>
          </Snackbar>
        )} 
        </div>

        <div className="notifications-right-wrapper">
          <div className="row text-center no-margin">
            <div className="col">
              <ToggleButtonGroup
                className="ToggleButtonGroup"
                exclusive
                value={alignment}
                onChange={handleAlignment}
              >
                <ToggleButton
                  value="view-scanned-details"
                  className="mr-3 ToggleButton text-center"
                  style={{
                    width: "250px",
                    height: "120px",
                    border: "1px solid gray",
                  }}
                >
                  <ul
                    className="text-center"
                    style={{ paddingLeft: "0rem", listStyle: "none" }}
                  >
                    <li
                      className="font-weight-bold qr_scans_text"
                      style={{
                        color: "black",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      Scans
                    </li>

                    <li
                      className="font-weight-bold mt-4"
                      style={{
                        color: "black",
                        fontSize: "40px",
                        textTransform: "none",
                      }}
                    >
                      {/* {scancount1 || 0} */}
                      {scanCount ? scanCount : scancount1 || null}
                    </li>
                  </ul>
                </ToggleButton>

                {(selectedCategory 
  ? scanMedicalByCategory?.length > 0 
                  : scanmedical?.length > 0) && (
                    <ToggleButton
                      value="pet-medical-details"
                      className="ml-3 ToggleButton"
                      style={{
                        width: "250px",
                        height: "120px",
                        border: "1px solid gray",
                      }}
                    >
                      <ul
                        className="text-center"
                        style={{ paddingLeft: "0rem", listStyle: "none" }}
                      >
                        <li
                          className="font-weight-bold medical_details_text"
                          style={{
                            color: "black",
                            fontSize: "18px",
                            textTransform: "none",
                          }}
                        >
                          Medical Details Accessed
                        </li>

                        <li
                          className="font-weight-bold mt-3"
                          style={{
                            color: "black",
                            fontSize: "40px",
                            textTransform: "none",
                          }}
                        >
                          {scancount2}
                        </li>
                      </ul>
                    </ToggleButton>
                  )
                }
             
                <ToggleButton
                  value="founder-details"
                  className="ml-3 ToggleButton"
                  style={{
                    width: "250px",
                    height: "120px",
                    border: "1px solid gray",
                  }}
                >
                  <ul
                    className="text-center"
                    style={{ paddingLeft: "0rem", listStyle: "none" }}
                  >
                    <li
                      className="font-weight-bold founder_details_text"
                      style={{
                        color: "black",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      Finder Details
                    </li>

                    <li
                      className="font-weight-bold mt-3"
                      style={{
                        color: "black",
                        fontSize: "40px",
                        textTransform: "none",
                      }}
                    >
                      {founderCount}
                    </li>
                  </ul>
                </ToggleButton>
              </ToggleButtonGroup>
              <FormControl
            variant="outlined"
            size="small"
            sx={{
              m:3,
              mt:7,
              minWidth: { xs: 250, sm: 250 }, // Reduce width on both mobile & laptop
              maxWidth: 150, // Ensures the width doesn't exceed this
              
            }}
          >
            <InputLabel>Select Category Wise Scan</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(event) => {
                const category = event.target.value;
                fetchScansByCategory(category);
                fetchFoundUserByCategory(category);
              }}
              label="Select Category Wise Scan"
              sx={{
                height: "50px", // Increase height
              }}
            >
              {productCategories &&
                productCategories.map((category, index) => (
                  <MenuItem key={index} value={category.product_category}>
                    {category.product_category}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
            </div>
          </div>
          {alignment === "view-scanned-details" && (
              <div className="mt-5">
                <h3 className="text-center mt-1 font-weight-bold">Scan History</h3>
                <hr style={{ marginBottom: "1rem" }} />

                <div className="mt-3">
                  {selectedCategory ? (
                    scanData?.length > 0 ? (
                      <div className="mt-1">
                        {scanData.map((res, index) => (
                          <div key={index} className="px-5 paddingleft text-left">
                           <div className="row d-flex align-items-center">
                           {/* Left - Date Section (Centered on Mobile) */}
                                <div className="col-1 col-md-1 text-left text-md-left">
                                  <ul style={{ listStyle: "none",marginLeft: "-40px" }}>
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.scan_date).toLocaleString("en-US", { weekday: "long" })}
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "22px" }}>
                                        <strong>{new Date(res.scan_date).toLocaleString("en-US", { day: "2-digit" })}</strong>
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.scan_date).toLocaleString("en-US", { month: "long" })}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-2 text-center imagecol">
                                  <img
                                    src={res?.image_location || "https://storage.googleapis.com/pettag/qr/assets/search.png"}
                                    // className="img-fluid"
                                    width="80px"
                                    height="80px"
                                    style={{ borderRadius: "50%" }}
                                    alt="scan image"
                                  />
                                </div>
                                {/* Center - Details Section (Stacks on Mobile) */}
                                <div
                                className={`adresscol ${window.innerWidth >= 768 ? "col-7 col-md-8 d-flex flex-column justify-content-center" : ""}`}>
                                  <span style={{ fontSize: "20px", fontWeight: "550" }}>{res.tag_number}</span>
                                  <div style={{ fontSize: "13px", marginTop: "5px" }}>
                                    <strong>{res.scan_type === "found" ? "Finder's Address" : "Address"}</strong> -{" "}
                                    <a
                                      href={`https://www.google.com/maps/search/?api=1&query=${res.latitude},${res.longitude}`}
                                      style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        display: "block",
                                        maxWidth: "100%",
                                      }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {res.scan_address}
                                    </a>
                                  </div>
                                  <span style={{ fontSize: "13px", marginTop: "5px" }}>
                                    <strong>Time</strong> -{" "}
                                    {new Date(res.scan_date).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}
                                  </span>
                                </div>
                              </div>
                            <hr />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center">No scan details captured.</div>
                    )
                  ) : (
                    scanneddetails?.length > 0 ? (
                      <div className="mt-1">
                        {scanneddetails.map((res, index) => (
                          res?.image_location && res?.scan_address ? (
                            <div key={index} className="px-5 paddingleft text-left" style={{
                              backgroundColor:
                                res.scan_type === "found"
                                  ? "#f0f8ff"
                                  : "transparent", // Background for "found"
                              paddingTop: "20px",
                              height: "auto",
                            }}
                            >
                              <div className="row d-flex align-items-center">
                           {/* Left - Date Section (Centered on Mobile) */}
                                <div className="col-1 col-md-1 text-left text-md-left">
                                  <ul style={{ listStyle: "none"}}>
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.scan_date).toLocaleString("en-US", { weekday: "long" })}
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "22px" }}>
                                        <strong>{new Date(res.scan_date).toLocaleString("en-US", { day: "2-digit" })}</strong>
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.scan_date).toLocaleString("en-US", { month: "long" })}
                                      </span>
                                    </li>
                                    {res.scan_type === "found" && (
                                    <li className="d-md-none">
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Status
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          color: "green",
                                        }}
                                      >
                                        {res.scan_type || "Not Available"}
                                      </span>
                                    </li>
                                  )}
                                  </ul>
                                </div>
                                <div className="col-2 text-center imagecol">
                                  <img
                                    src={res?.image_location || "https://storage.googleapis.com/pettag/qr/assets/search.png"}
                                    // className="img-fluid"
                                    width="80px"
                                    height="80px"
                                    style={{ borderRadius: "50%" }}
                                    alt="scan image"
                                  />
                                </div>
                                {/* Center - Details Section (Stacks on Mobile) */}
                                <div
                                className={`adresscol ${window.innerWidth >= 768 ? "col-7 col-md-8 d-flex flex-column justify-content-center" : ""}`}>
                                  <span style={{ fontSize: "20px", fontWeight: "550" }}>{res.tag_number}</span>
                                  <div style={{ fontSize: "13px", marginTop: "5px" }}>
                                    <strong>{res.scan_type === "found" ? "Finder's Address" : "Address"}</strong> -{" "}
                                    <a
                                      href={`https://www.google.com/maps/search/?api=1&query=${res.latitude},${res.longitude}`}
                                      style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        display: "block",
                                        maxWidth: "100%",
                                      }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {res.scan_address}
                                      {/* {address} */}
                                    </a>
                                  </div>
                                  <span style={{ fontSize: "13px", marginTop: "5px" }}>
                                    <strong>Time</strong> -{" "}
                                    {new Date(res.scan_date).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}
                                  </span>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ) : null
                        ))}
                      </div>
                    ) : (
                      <div className="text-center">no scans were reported</div>
                    )                    
                  )}
                </div>
              </div>
            )}     
          {alignment === "pet-medical-details" ? (
            <div>
              <h3 className="mt-5 px-5 paddingleft text-center font-weight-bold">
                Medical data Access History
              </h3>
              <hr />
              {selectedCategory ? (
                    scanMedicalByCategory?.length > 0 ? (
                      <div className="mt-1">
                        {scanMedicalByCategory.map((res) => {
                          return (
                            <div
                              className="px-5 paddingleft text-left"
                              style={{ width: "100%" }}
                            >
                              <div className="row">
                                <div className="col-1 text-left">
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      marginLeft: "-40px",
                                    }}
                                  >
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.found_date).toLocaleString(
                                          "en-US",
                                          { weekday: "long" }
                                        )}
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "22px" }}>
                                        <strong>
                                          {new Date(res.found_date).toLocaleString(
                                            "en-US",
                                            { day: "2-digit" }
                                          )}
                                        </strong>
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.found_date).toLocaleString(
                                          "en-US",
                                          { month: "long" }
                                        )}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-2 text-center imagecol">
                                  {res?.product_category === "QR HUMAN" ||
                                  res?.product_category === "QR Human" ? (
                                    <img
                                      src={
                                      res?.image_location ? res?.image_location :  "https://storage.googleapis.com/pettag/qr/assets/human.png"
                                      }
                                      width="80px"
                                      height="80px"
                                      style={{ borderRadius: "50%" }}
                                      alt="cat and dog"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        res?.image_location || vectorimg[res?.valuable_type] || "https://storage.googleapis.com/pettag/qrtagdev/assets/catdog1.jpg"
                                      }
                                      width="80px"
                                      height="80px"
                                      style={{ borderRadius: "50%" }}
                                      alt="cat and dog"
                                    />
                                  )}
                                </div>
                                <div className=" adresscol">
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {res.tag_number}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Address</strong> - {res.address}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Time</strong> -{" "}
                                    {new Date(res.found_date).toLocaleTimeString(
                                      "en-US",
                                      {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }
                                    )}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Name</strong> - {res.fullname}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Email</strong> - {res.email}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Phone Number</strong> - {res.phonecode}{" "}
                                    {res.phone}
                                  </span>
                                  <br />
                                </div>
                                <div className="col-2">
                                  <a
                                    href={
                                      "https://www.google.com/maps/search/?api=1&query=" +
                                      res.latitude +
                                      "," +
                                      res.longitude
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={
                                        "https://storage.googleapis.com/pettag/qrtagdev/assets/map.png"
                                      }
                                      width="50px"
                                      height="50px"
                                      alt="map"
                                    />
                                  </a>
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="text-center">Scan details are not available.</div>
                    )
                  ) : (
                    scanmedical?.length > 0 ? (
                      <div className="mt-1">
                        {scanmedical.map((res) => {
                          return (
                            <div
                              className="px-5 paddingleft text-left"
                              style={{ width: "100%" }}
                            >
                              <div className="row">
                                <div className="col-1 text-left">
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      marginLeft: "-40px",
                                    }}
                                  >
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.found_date).toLocaleString(
                                          "en-US",
                                          { weekday: "long" }
                                        )}
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "22px" }}>
                                        <strong>
                                          {new Date(res.found_date).toLocaleString(
                                            "en-US",
                                            { day: "2-digit" }
                                          )}
                                        </strong>
                                      </span>
                                    </li>
                                    <li>
                                      <span style={{ fontSize: "11px" }}>
                                        {new Date(res.found_date).toLocaleString(
                                          "en-US",
                                          { month: "long" }
                                        )}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-2 text-center imagecol">
                                  {res?.product_category === "QR HUMAN" ||
                                  res?.product_category === "QR Human" ? (
                                    <img
                                      src={
                                      res?.image_location ? res?.image_location :  "https://storage.googleapis.com/pettag/qr/assets/human.png"
                                      }
                                      width="80px"
                                      height="80px"
                                      style={{ borderRadius: "50%" }}
                                      alt="cat and dog"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        res?.image_location || vectorimg[res?.valuable_type] || "https://storage.googleapis.com/pettag/qrtagdev/assets/catdog1.jpg"
                                      }
                                      width="80px"
                                      height="80px"
                                      style={{ borderRadius: "50%" }}
                                      alt="cat and dog"
                                    />
                                  )}
                                </div>
                                <div className=" adresscol">
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {res.tag_number}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Address</strong> - {res.address}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Time</strong> -{" "}
                                    {new Date(res.found_date).toLocaleTimeString(
                                      "en-US",
                                      {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }
                                    )}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Name</strong> - {res.fullname}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Email</strong> - {res.email}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Phone Number</strong> - {res.phonecode}{" "}
                                    {res.phone}
                                  </span>
                                  <br />
                                </div>
                                <div className="col-2">
                                  <a
                                    href={
                                      "https://www.google.com/maps/search/?api=1&query=" +
                                      res.latitude +
                                      "," +
                                      res.longitude
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={
                                        "https://storage.googleapis.com/pettag/qrtagdev/assets/map.png"
                                      }
                                      width="50px"
                                      height="50px"
                                      alt="map"
                                    />
                                  </a>
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="text-center">Scan details are not available.</div>
                    )
                  )}
            </div>
          ) : (
            ""
          )}
          {alignment === "founder-details" && (
            <div>
              <h3 className="mt-5 text-center font-weight-bold">
                Finder Details
              </h3>

              <hr />

              {selectedCategory ? (
                founderData?.length > 0 ? (
                  <div className="mt-1">
                    {founderData.map((res,index) => {
                      return (
                    <div key={index} className="px-5 paddingleft text-left">
                    <div className="row">
                      <div className="col-1 text-left">
                        <ul
                          style={{
                            listStyle: "none",
                            marginLeft: "-40px",
                          }}
                        >
                          <li>
                            <span style={{ fontSize: "11px" }}>
                              {new Date(res.scan_date).toLocaleString(
                                "en-US",
                                { weekday: "long" }
                              )}
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "22px" }}>
                              <strong>
                                {new Date(res.scan_date).toLocaleString(
                                  "en-US",
                                  { day: "2-digit" }
                                )}
                              </strong>
                            </span>
                          </li>
                          <li>
                            <span style={{ fontSize: "11px" }}>
                              {new Date(res.scan_date).toLocaleString(
                                "en-US",
                                { month: "long" }
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-2 text-center imagecol">
                        <img
                          src={
                            res?.image_location ||
                            "https://storage.googleapis.com/pettag/qr/assets/search.png"
                          }
                          width="80px"
                          height="80px"
                          style={{ borderRadius: "50%" }}
                          alt="founder"
                        />
                      </div>
                      <div className="adresscol">
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "550",
                          }}
                        >
                          {res.founder_firstname} {res.founder_lastname}
                        </span>
                        <br />
                        <span style={{ fontSize: "13px" }}>
                          <strong>Email</strong> - {res.founder_email}
                        </span>
                        <br />
                        <span style={{ fontSize: "13px" }}>
                          <strong>Contact Number</strong> -{" "}
                          {res.founder_phcode} {res.founder_contactno}
                        </span>
                        <br />
                        <span style={{ fontSize: "13px" }}>
                          <strong>Tag Number</strong> - {res.tag_number}
                        </span>
                        <br />
                        <span style={{ fontSize: "13px" }}>
                          <strong>Time</strong> -{" "}
                          {new Date(res.scan_date).toLocaleTimeString(
                            "en-US",
                            { hour: "2-digit", minute: "2-digit" }
                          )}
                        </span>
                        <br />
                        {/* <span style={{ fontSize: "13px" }}>
                          <strong>Location</strong> -{" "}
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${res.latitude},${res.longitude}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View on map
                          </a>
                        </span> */}
                      </div>
                    </div>
                    <hr />
                  </div>)
                    })}
                  </div>
                ) : (
                  <div className="text-center">No finder details captured.</div>
                )
              ) : (
                founderDetails?.length > 0 ? (
                  <div className="mt-1">
                    {founderDetails.map((res,index) => {
                      return (
                      <div key={index} className="px-5 paddingleft text-left">
                      <div className="row">
                        <div className="col-1 text-left">
                          <ul
                            style={{
                              listStyle: "none",
                              marginLeft: "-40px",
                            }}
                          >
                            <li>
                              <span style={{ fontSize: "11px" }}>
                                {new Date(res.scan_date).toLocaleString(
                                  "en-US",
                                  { weekday: "long" }
                                )}
                              </span>
                            </li>
                            <li>
                              <span style={{ fontSize: "22px" }}>
                                <strong>
                                  {new Date(res.scan_date).toLocaleString(
                                    "en-US",
                                    { day: "2-digit" }
                                  )}
                                </strong>
                              </span>
                            </li>
                            <li>
                              <span style={{ fontSize: "11px" }}>
                                {new Date(res.scan_date).toLocaleString(
                                  "en-US",
                                  { month: "long" }
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-2 text-center imagecol">
                          <img
                            src={
                              res?.image_location ||
                              "https://storage.googleapis.com/pettag/qr/assets/search.png"
                            }
                            width="80px"
                            height="80px"
                            style={{ borderRadius: "50%" }}
                            alt="founder"
                          />
                        </div>
                        <div className="adresscol">
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "550",
                            }}
                          >
                            {res.founder_firstname} {res.founder_lastname}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Email</strong> - {res.founder_email}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Contact Number</strong> -{" "}
                            {res.founder_phcode} {res.founder_contactno}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Tag Number</strong> - {res.tag_number}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Time</strong> -{" "}
                            {new Date(res.scan_date).toLocaleTimeString(
                              "en-US",
                              { hour: "2-digit", minute: "2-digit" }
                            )}
                          </span>
                          <br />
                          {/* <span style={{ fontSize: "13px" }}>
                            <strong>Location</strong> -{" "}
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${res.latitude},${res.longitude}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View on map
                            </a>
                          </span> */}
                        </div>
                      </div>
                      <hr />
                    </div>
                    )})}
                  </div>
                ) : (
                  <div className="text-center">No finder details captured.</div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      {renderQRModal()}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {loader && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Notifications;
