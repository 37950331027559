import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from "@material-ui/core";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { switchUrls } from "../../api";
const baseURL = switchUrls("gateway");

function Findermodal({ findermodal, setfindermodal, tagnumber, image,productcategory }) {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [defaultRegion, setDefaultRegion] = useState("");
  const [error,seterror] = useState(false)
  const [loading,setloading] = useState(false)

  const getLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          latitude: position?.coords?.latitude,
          longitude: position?.coords?.longitude,
        });
      });
    }
  };
  useEffect(() => {
    console.log("wrojrj");
    getLocation();
  }, []);
  const [formdata, setformdata] = useState({});
  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        setloading(true)
        const {data} = await axios.get("https://ipapi.co/json/")
        // console.log(data, "dtattata");

        if (data && data.country_code) {
          const userCountryCode = data.country_code.toLowerCase();
          // console.log(userCountryCode, "dtattata");
          setDefaultRegion(userCountryCode);
          setloading(false)
        } else {
           setDefaultRegion("in");
           setloading(false)
        }
      } catch (error) {
        console.log("Error fetching user country:", error);
        setDefaultRegion("in");
        setloading(false)
      }
    };

    fetchUserCountry();
  }, []);
  const submithandler = async (e) => {
    try {
      e.preventDefault();
      
      if((formdata?.contact_no && formdata?.phCode) || (formdata?.contact_no && defaultRegion) ){

        const { data } = await axios.post(`${baseURL}/api/storeFinder`, {
          formdata,
          tagnumber,
          location,
          image,
          productcategory
        });
        if (data?.status) {
          setfindermodal(false);
        } else {
          alert(data?.message);
          setfindermodal(false);
        }

      }
      else{
 seterror(true)
      }
   
    } catch (error) {
      console.log(error);
    }
  };
  const phonehandler = async (value, details) => {
    try {
      seterror(false)
      let { dialCode } = details;
      if (dialCode && value) {
        const phCode = "+" + dialCode;
        const sanitizedValue = value.replace(/[^0-9]/g, "");

        const phoneNumber = sanitizedValue.replace(
          phCode.replace(/[^0-9]/g, ""),
          ""
        );
        setformdata({
          ...formdata,
          contact_no: phoneNumber,
          phcode: phCode,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changehandler = async (e) => {
    try {
      // console.log(e.target.name);

      setformdata({ ...formdata, [e.target.name]: e.target.value });
    } catch (error) {
      console.log("er");
    }
  };

  return (
    <>
   {
    loading ? <>
           <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
    </> : <>
       <Dialog
        className="emerd-dilog"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        fullWidth
        maxWidth="sm"
        open={findermodal}
        onClose={() => setfindermodal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="inner-emerd-div">
          <div style={{ padding: "0px", margin: "0px" }}>
            <h1
              style={{
                margin: "15px",
                font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                letterSpacing: "normal",
              }}
            >
              Please Enter the Details.
            </h1>

            <form
              className="emerg_call"
              style={{
                margin: "5px 25px",
                display: "flex",
                flexDirection: "column",
              }}
              onSubmit={submithandler}
            >
              <div className="reportfoundusername">
                <div className="namediv">
                  <TextField
                    label="First Name"
                    value={formdata?.first_name}
                    onChange={changehandler}
                    name="first_name"
                    variant="outlined"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="namediv">
                  <TextField
                    label="Last Name"
                    value={formdata?.last_name}
                    onChange={changehandler}
                    name="last_name"
                    variant="outlined"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>

              <div className="reportfoundusername">
                {defaultRegion && (
                  <MuiPhoneInput
                    required
                    label="Contact No"
                    defaultCountry={defaultRegion}
                    value={`+${formdata?.phcode} ${formdata?.contact_no}`}
                    onChange={phonehandler}
                    error = {error}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    variant="outlined"
                    name="mobile"
                    helperText = {error ? "Please enter a valid phone number" : ""}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              </div>
              <div className="reportfoundusername">
                <TextField
                type="email"
                  label="Email"
                  value={formdata?.email}
                  onChange={changehandler}
                  name="email"
                  variant="outlined"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "7px",
                  marginTop: "15px",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    margin: "10px 20px",
                    marginBottom: "35px",
                    flex: 0.5,
                    border: "none",
                  }}
                  className="otp-buttons bg-warning confirm subbutton"
                >
                  CONFIRM
                </Button>

                <Button
                  variant="contained"
                  className="otp-buttons cancel subbutton"
                  style={{
                    margin: "10px 20px",
                    marginBottom: "35px",
                    flex: 0.5,
                    border: "none",
                  }}
                  onClick={() => setfindermodal(false)}
                >
                  CANCEL
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
   }
    </>
  );
}

export default Findermodal;
