import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../reportfoundlf/reportfoundlf.component.css";
// import CatDog from '../../assets/keyicon.png';
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";

import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import CryptoJS from "crypto-js";
import { useEffect } from "react";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

export default class ReportFoundLF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: true,
      subMenu: true,
      formData: [],
      tag_number: "",
      //   passport_number: "",
      //   valuable_desc: "",

      user: "",
    };

    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  componentDidMount() {
    //SQL_Close
    // axios
    //   .get("/api/getTagExtraDetailsByPrdId/" + this.props.match.params.email)
    //   .then((response) => {
    //     // console.log(response.data);
    //     if (response.data.length === 0) {
    //       window.location.href = "/#/tags-not-found";
    //     } else {
    //       response.data.data.map((el) => {
    //         // console.log("el--->", el);
    //         this.state[el.protecteditem_levelname] =
    //           el.TagActivateProductDetails_value;
    //         // console.log("el--->", this.state[el.protecteditem_levelname]);
    //       });

    //       this.setState({
    //         tag_number: this.props.match.params.tag_number,
    //         // warrantyPeriod: response.data[0].warrantyPeriod ? response.data[0].warrantyPeriod : 0,
    //         // warrantyType: response.data[0].warrantyType ? response.data[0].warrantyType  : 'year',
    //         // valuable_desc: response.data[0].valuable_desc,
    //         formData: response.data.data,
    //         user: localStorage.getItem("mid"),
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });
    //SQL_Close
    // axios
    //   .get("/api/getLftagsbytgNumber/" + this.props.match.params.tag_number)
    //   .then((response) => {
    //     // console.log(response.data);
    //     if (response.data.length === 0) {
    //       window.location.href = "/#/tags-not-found";
    //     } else {
    //       this.setState({
    //         warrantyPeriod: response.data[0].warrantyPeriod
    //           ? response.data[0].warrantyPeriod
    //           : 0,
    //         warrantyType: response.data[0].warrantyType
    //           ? response.data[0].warrantyType
    //           : "year",
    //         valuable_desc: response.data[0].valuable_desc,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }

  handleChange(event) {
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      // console.log(sizeinmb);
      if (sizeinmb > 2) {
        this.setState({ message: "File size should be less than 2 MB" });
      } else {
        this.setState({ message: "" });
        this.setState({
          selectedImage: event.target.files[0],
          imageUrl: URL.createObjectURL(event.target.files[0]),
        });
      }
    }
  }

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  transferTagApi() {
    var reqObj = this.state;
    reqObj["uid"] = localStorage.getItem("uid");

    // console.log("reqObj--->", reqObj);
    axios
      .post("/api/migrateLFTag", this.state, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log("Tag Transffered");
          //  window.location.href = "/dashboard/" + localStorage.getItem("uid");
        } else {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        }
      })
      .catch((error) => {
        // console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Tag Details Update Failed",
        });
      });
  }

  submitHandler = (e) => {
    e.preventDefault();

    if (this.state.tag_number.length > 0) {
      // console.log("this.state.tag_number--->", this.state.tag_number);
      window.location.href = "/#/scan/" + this.state.tag_number;
    }

    // console.log(this.state);
    //SQL_Close
    // axios
    //   .post("/api/updateTagExtraDetailsById", this.state, {
    //     headers: { Authorization: usertoken },
    //   })
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.status === 200) {
    //       this.setState({
    //         snackbaropen: true,
    //         snackbarmsg: "Tag Details Updated",
    //       });
    //       setTimeout(() => {
    //         window.location.href = "/dashboard/" + localStorage.getItem("uid");
    //       }, 2000);
    //     } else {
    //       this.setState({ snackbaropen: true, snackbarmsg: response.data });
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //     this.setState({
    //       snackbaropen: true,
    //       snackbarmsg: "Tag Details Update Failed",
    //     });
    //   });
  };

  render() {
    const {
      sidebar,
      subMenu,
      tag_number,
      formData,
      user,
      warrantyPeriod,
      warrantyType,
      valuable_desc,
    } = this.state;
    return (
      <>
        <Navbar />

        <div className="reportLost-main-wrapper">
          {/* <div className="reportLost-left-wrapper">
            <div className="reportLost-sidebar-toggle-btn-wrapper">
              <button
                className="reportLost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "reportLost-sidebar-container-hide"
                  : "reportLost-sidebar-container"
              }
            >
              <button
                className="reportLost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "reportLost-sidebar-menu-ul-hide"
                    : "reportLost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
              >
                ACTIVATE TAG
              </button>

              <button
                className="dashboard-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div> */}

          <div className="newreportLost-right-wrapper">
            <div className="container body-login-lf bg-white mb-5">
              <div className="row mt-5" id="pet-details-fill-2">
                <div className="col px-3 py-4 text-left">
                  <div className="row text-center px-4">
                    <div className="col row-underline">
                      <p>
                        <strong>Report Found</strong>
                      </p>
                    </div>
                  </div>
                  <div className="mx-4 mt-4 mb-3">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <img src="https://www.tag8.in/assets/front/images/mapImg.png" />
                    </div>
                    <form onSubmit={this.submitHandler}>
                      <div className="row">
                        <div className="col extrmrgin  text-center">
                          <span className="label-petdata">Owner ID</span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={tag_number}
                            name="tag_number"
                            required
                          />
                          <br />
                          {/*  <span className="input-pet-details">PET111</span> */}
                        </div>
                        <br />

                        <br />

                        <br />
                      </div>

                      <div className="row" style={{ justifyContent: "center" }}>
                        <div className="col-4 ">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{ width: "100%" }}
                            className="submit-login bg-warning text-white mt-2"
                          >
                            Report Found
                          </Button>
                        </div>
                      </div>

                      <Snackbar
                        open={this.state.snackbaropen}
                        autoHideDuration={3000}
                        onClose={this.snackbarClose}
                        message={this.state.snackbarmsg}
                        action={[
                          <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                          >
                            X
                          </IconButton>,
                        ]}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
