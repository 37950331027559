import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../oldtags-details/oldtagsdtls.component.css";
// import CatDog from '../../assets/keyicon.png';
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";

import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

export default class OldTagDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: true,
      subMenu: true,
      tag_number: "",
      passport_number: "",
      valuable_desc: "",

      warrantyPeriod: "",
      warrantyType: "",
      surname: "",
      nationality: "",
      placeofIssue: "",
      passport_givenName: "",
      dateofIssue: "",
      dateofExpiry: "",
      passport_Address: "",
      user: "",
    };

    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  componentDidMount() {
    //SQL_Close
    // axios
    //   .get("/api/getLftagsbytgNumber/" + this.props.match.params.tag_number)
    //   .then((response) => {
    //     // console.log(response.data);
    //     if (response.data.length === 0) {
    //       window.location.href = "/#/tags-not-found";
    //     } else {
    //       // console.log(
    //       //   "response.data.data.passport_number-->",
    //       //   (new Date(response.data[0].dateofIssue)).toISOString().split('T')[0],
    //       //   new Date(response.data[0].dateofIssue)

    //       // );
    //       let issuDt = new Date(response.data[0].dateofIssue)
    //         .toISOString()
    //         .split("T")[0];

    //       this.setState({
    //         tag_number: this.props.match.params.tag_number,
    //         passport_number: response.data[0].passport_number,
    //         passport_number: response.data[0].passport_number,
    //         valuable_desc: response.data[0].valuable_desc,
    //         warrantyPeriod: response.data[0].warrantyPeriod,
    //         warrantyType: response.data[0].warrantyType,
    //         surname: response.data[0].surname,
    //         nationality: response.data[0].nationality,
    //         placeofIssue: response.data[0].placeofIssue,
    //         passport_givenName: response.data[0].passport_givenName,
    //         dateofIssue: issuDt.toString(),
    //         dateofExpiry: response.data[0].dateofExpiry,
    //         passport_Address: response.data[0].passport_Address,
    //         user: localStorage.getItem("mid"),
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });

    // $("#profile-image-uploadActi").on('click', function () {
    //     $("#choose-profile-imageActi").trigger('click');
    // });

    // $("#profile-image-uploadActi-1").on('click', function () {
    //     $("#choose-profile-imageActi").trigger('click');
    // });
  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }

  handleChange(event) {
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      // console.log(sizeinmb);
      if (sizeinmb > 2) {
        this.setState({ message: "File size should be less than 2 MB" });
      } else {
        this.setState({ message: "" });
        this.setState({
          selectedImage: event.target.files[0],
          imageUrl: URL.createObjectURL(event.target.files[0]),
        });
      }
    }
  }

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  deleteimage = (e) => {
    // axios.post('/api/key/deletekeyimage',this.state).then(response =>{
    //     console.log(response)
    //     if(response.data === 'Key Image removed'){
    //         this.setState({imageUrl:CatDog,key_imagelocation:null,key_imagename:null,})
    //     }
    //     else if(response.data === 'wrong data'){
    //         this.setState({snackbaropen:true,snackbarmsg:'Image could not be deleted'})
    //     }
    // })
    // .catch(error =>{
    //     this.setState({snackbaropen:true,snackbarmsg:'Unsuccessful'})
    // })
  };

  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state);
    //SQL_Close
    // axios
    //   .post("/api/updatePassportDetailsById", this.state, {
    //     headers: { Authorization: usertoken },
    //   })
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.status === 200) {
    //       this.setState({
    //         snackbaropen: true,
    //         snackbarmsg: "Passport Details Updated",
    //       });
    //       setTimeout(() => {
    //         window.location.href = "/dashboard/" + localStorage.getItem("uid");
    //       }, 2000);
    //       //  window.location.href = "/dashboard/" + localStorage.getItem("uid");
    //     } else {
    //       this.setState({ snackbaropen: true, snackbarmsg: response.data });
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //     this.setState({
    //       snackbaropen: true,
    //       snackbarmsg: "Passport Details Update Failed",
    //     });
    //   });
  };

  render() {
    const {
      sidebar,
      subMenu,
      tag_number,
      passport_number,
      surname,
      nationality,
      placeofIssue,
      passport_givenName,
      dateofIssue,
      dateofExpiry,
      passport_Address,
      user,
      warrantyPeriod,
      warrantyType,
      valuable_desc,
    } = this.state;
    return (
      <>
        <Navbar />

        <div className="reportLost-main-wrapper">
          <div className="reportLost-left-wrapper">
            <div className="reportLost-sidebar-toggle-btn-wrapper">
              <button
                className="reportLost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "reportLost-sidebar-container-hide"
                  : "reportLost-sidebar-container"
              }
            >
              <button
                className="reportLost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "reportLost-sidebar-menu-ul-hide"
                    : "reportLost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
              >
                ACTIVATE TAG
              </button>

              <button
                className="dashboard-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>

          <div className="reportLost-right-wrapper">
            <div className="container body-login bg-white mb-5">
              <div className="row mt-5" id="pet-details-fill-2">
                <div className="col px-3 py-4 text-left">
                  <div className="row text-center px-4">
                    <div className="col row-underline">
                      <p>
                        <strong>Passport Details</strong>
                      </p>
                    </div>
                  </div>
                  <div className="mx-4 mt-4 mb-3">
                    <form onSubmit={this.submitHandler}>
                      <div className="row">
                        <div className="col extrmrgin">
                          <span className="label-petdata">Owner ID</span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={tag_number}
                            name="tag_number"
                            readOnly
                            required
                          />
                          <br />
                          {/*  <span className="input-pet-details">PET111</span> */}
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">
                            Warranty Period{" "}
                          </span>
                          <br />
                          <input
                            type="number"
                            min="1"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={warrantyPeriod}
                            name="warrantyPeriod"
                          />
                          <br />
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">
                            Warranty Duration{" "}
                          </span>
                          <br />
                          {/* <input type="textbox" className="input-pet-details" onChange={this.changeHandler} value={warrantyType} name="warrantyType"   />  */}
                          <select
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={warrantyType}
                            name="warrantyType"
                          >
                            <option value="year">year</option>
                            <option value="month">month</option>
                            <option value="day">day</option>
                          </select>
                          <br />
                          {/*  <span className="input-pet-details">PET111</span> */}
                        </div>
                        <br />
                        <div className="col extrmrgin">
                          <span className="label-petdata">Descrption </span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={valuable_desc}
                            name="valuable_desc"
                          />
                          <br />
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">Surname </span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={surname}
                            name="surname"
                          />
                          <br />
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">Given Name </span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={passport_givenName}
                            name="passport_givenName"
                          />
                          <br />
                          {/*  <span className="input-pet-details">PET111</span> */}
                        </div>
                        <br />
                        <div className="col extrmrgin">
                          <span className="label-petdata">Nationality </span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={nationality}
                            name="nationality"
                          />
                          <br />
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">
                            Passport Number{" "}
                          </span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={passport_number}
                            name="passport_number"
                          />
                          <br />
                          {/*  <span className="input-pet-details">PET111</span> */}
                        </div>
                        <br />
                        <div className="col extrmrgin">
                          <span className="label-petdata">Place of Issue </span>
                          <br />
                          <input
                            type="textbox"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={placeofIssue}
                            name="placeofIssue"
                          />
                          <br />
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">Date of Issue </span>
                          <br />
                          <input
                            type="date"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={dateofIssue}
                            name="dateofIssue"
                          />
                          <br />
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">Date of Expiry</span>
                          <br />
                          <input
                            type="date"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={dateofExpiry}
                            name="dateofExpiry"
                          />
                          <br />
                          {/*  <span className="input-pet-details">PET111</span> */}
                        </div>
                        <br />

                        <div className="col extrmrgin">
                          <span className="label-petdata">Address</span>
                          <br />
                          <input
                            type="textarea"
                            className="input-pet-details"
                            onChange={this.changeHandler}
                            value={passport_Address}
                            name="passport_Address"
                          />
                          <br />
                        </div>
                        <br />
                      </div>

                      <div className="row">
                        <div className="col">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{ width: "100%" }}
                            className="submit-login bg-warning text-white mt-2"
                          >
                            SUBMIT
                          </Button>
                        </div>
                        <div className="col">
                          <Button
                            variant="contained"
                            type="button"
                            style={{ width: "100%" }}
                            onClick={() => {
                              window.location.href =
                                "/dashboard/" + localStorage.getItem("uid");
                            }}
                            className="submit-login bg-white mt-2"
                          >
                            CANCEL
                          </Button>
                        </div>
                      </div>
                      <Snackbar
                        open={this.state.snackbaropen}
                        autoHideDuration={3000}
                        onClose={this.snackbarClose}
                        message={this.state.snackbarmsg}
                        action={[
                          <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                          >
                            X
                          </IconButton>,
                        ]}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
