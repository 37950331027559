import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import axios from "axios";
import Button from "@material-ui/core/Button";
import PetReportFound from "../reportfound/viewpetdetails/viewpetdetails.component.jsx";
import GenericReportFound from "../reportfound/reportfound.component";
import NotFoundDetails from "../not-foundtags/notfound.component.jsx";
import "./scanrouting.component.css";
import ViewHumanDetails from "../reportfound/viewhumandetails/viewhumandetails.jsx";
import { switchUrls } from "../../api/index.js";
import { PRODUCTCATEGORY_ETAGS } from "../../utils/setValues.js";
import Newscan from "./newscan/Newscan.jsx";
const baseURL = switchUrls("gateway");

function ScanRouting(props) {
  const [loader, setloader] = useState(false);
  const [deleteflag, setDeleteFlag] = useState(false);
  const [notfound, shownotfound] = useState(false);
  const [notactivated, shownotactivated] = useState(false);
  const [initialletter, showinitialletter] = useState("");
  const [etagdetails, setetagdetails] = useState({});
  const [ownerdetails, setownerdetails] = useState({});
  const [tagNumberProp,setTagNumberProp] = useState('');

  const getTagnumber = async (tagnumber) => {
    try {
      const { data } = await axios.get(`${baseURL}/api/getTagNumber`, {
        params: {
          tag: tagnumber,
        },
      });
      console.log(data)
      if (data?.tagResult?.isDeleted || data?.message === "Tag not found") {
        window.location.href = "/#/tags-not-found";
        setDeleteFlag(true);
        shownotfound(true);
      }

      showinitialletter(data?.tagResult?.product_category);

      if (PRODUCTCATEGORY_ETAGS?.includes(data?.tagResult?.product_category)) {
        checkEtagnumber(tagnumber, data?.tagResult?.product_category);
      } else {
        checkTagnumber(tagnumber);
      }
    } catch (error) {
      setloader(false);

      return;
    }
  };

  const checkTagnumber = async (tagnumber) => {
    try {
      const state = {
        tag_number: tagnumber,
      };
      const { data } = await axios.post(
        `${baseURL}/api/checkTagNumberWithoutHeader`,
        state
      );
      localStorage.setItem("tag_number", tagnumber);
      if (data?.msg === "Tag not Found by the specified Number") {
        checkTaginLF(tagnumber);
      } else if (
        data?.msg === "Tag Activated Successfully" &&
        data?.tag_data?.tag_status !== "cancel" &&
        (data?.tag_data?.company_name === "Tata_aig" ||
          data?.tag_data?.product_category.startsWith("Dolphin") ||
          data?.tag_data?.product_category.startsWith("Smart"))
      ) {
        window.location.href = "/#/welcome/" + tagnumber;
      } else if (
        data?.msg === "Tag Activated Successfully" &&
        data?.tag_data?.tag_status !== "cancel"
      ) {
        sessionStorage.setItem("tag_number", tagnumber);
        window.location.href = "/#/login";
      } else {
        setloader(false);
        //show the normal scan page
        return;
      }
    } catch (error) {
      setloader(false);

      return;
    }
  };

  const checkEtagnumber = async (tagnumber, productcategory) => {
    try {
      const state = {
        tag_number: tagnumber,
        product_category: productcategory,
      };

      const { data } = await axios.post(`${baseURL}/api/checketag`, state);
      if (
        data?.tagdetails?.email &&
        data?.tagdetails?.tag_status === "inactive"
      ) {
        window.location.href = "/#/welcome/" + tagnumber;
      } else if (data?.tagdetails?.tag_status === "inactive") {
        sessionStorage.setItem("tag_number", tagnumber);
        window.location.href = "/#/login";
        //setloader(false)
      } else {
        setetagdetails(data?.tagdetails);
        setownerdetails(data?.userdetails);
        setloader(false);
        //normal scan page
        return;
      }
    } catch (error) {
      //handel error or it will give blank page
      window.location.href = "/#/tags-not-found";
      //setloader(false);
      return;
    }
  };

  useEffect(() => {
    setloader(true);
    let tagnumber = props?.match?.params?.tag_number || '';
    if (tagnumber) {      
      if(tagnumber.includes('OWNERID')){
        tagnumber=tagnumber.split('OWNERID')[1];
      }
      setTagNumberProp(tagnumber);
      getTagnumber(tagnumber);
    }
  }, []);

  const checkTaginLF = (tagnumber) => {
    // console.log(tagnumber, "numberererer");
    //SQL_Close
    // axios
    //   .get(`/api/getAllLFtagsById/${tagnumber}`, {})
    //   .then((res) => {
    //     // console.log("res-->", res);
    //     if (res.data.data.length === 0) {
    //       //shownotfound(true);

    //       window.location.href = "/#/tags-not-found";
    //       // /tags-not-found
    //     } else if (res.data.data.length > 0) {
    //       // console.log("res.data.data--->", res.data.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error.message);
    //   });
  };

  const handleClose = () => {
    shownotfound(false);
  };

  const handleClose1 = () => {
    shownotactivated(false);
  };

  const onNotFound = () => {
    shownotfound(false);

    const initial = tagNumberProp.substring(0, 3);

    window.location.href = "/";
  };

  const onActivated = () => {
    window.location.href = `/#/welcome/${tagNumberProp}`;
  };

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      ) : (
        <>
          {initialletter === "QR PET" || initialletter === "QR Pet" ? (
            <PetReportFound tag_number={tagNumberProp} />
          ) : initialletter === "QR Human" ? (
            <ViewHumanDetails tag_number={tagNumberProp} />
          ) : PRODUCTCATEGORY_ETAGS.includes(initialletter) ? (
            <>
              <Newscan
                tagdetails={etagdetails}
                ownerdetails={ownerdetails}
                productcategory={initialletter}
              />
            </>
          ) : initialletter?.length ? (
            //GenericReportFound component contains the scan details for all other product categories
            <GenericReportFound tag_number={tagNumberProp} productcategory={initialletter} />
          ) : null}
          {/* 
      {initialletter == "QR PET" || initialletter === "QR Pet" ? (
        <PetReportFound tag_number={tagNumberProp} />
      ) : (
        <GenericReportFound tag_number={tagNumberProp} />
      )} */}

          <Dialog
            open={notfound}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent className="small__popup">
              <h3>Tag number not found</h3>

              <Button
                variant="outlined"
                className="small_pop_btn"
                onClick={onNotFound}
              >
                Ok
              </Button>
            </DialogContent>
          </Dialog>

          <Dialog
            open={notactivated}
            onClose={handleClose1}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent className="small__popup">
              <h1
                style={{
                  margin: "30px",
                  marginTop: "20px",
                  marginBottom: "10px",
                  font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                }}
              >
                Tag is not activated. To activate this tag, follow the steps
                given below:
              </h1>
              <hr />
              <div className="u-div p-3">
                <p className="popup_li">1. Click on OK</p>
                <p className="popup_li">
                  2. Login using Email ID/Google login.
                </p>
                <p className="popup_li">
                  3. Click on Activate tag on the sidebar.
                </p>
                <p className="popup_li">
                  4. Enter the owner id printed on your tag and click on
                  Activate tag button.
                </p>
              </div>
              <hr />
              <div className="text-center pb-3 pt-3 b-div">
                <Button
                  variant="outlined"
                  className="small_pop_btn"
                  onClick={onActivated}
                >
                  Ok
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}

export default ScanRouting;
